
import { computed } from 'vue'
import { useStore } from 'vuex'
export default function useWish({ route }) {
  const store = useStore()
  const showCartBtn = computed(() => {
    if (!store) {
      console.error('store is not exist')
      return {}
    } 
    return store.getters['productDetail/common/show_cart_btn']
  })

  const showWish = computed(() => {
    //详情页分享
    return ['page_goods_detail'].includes(route.value.name)
    && showCartBtn.value
  })
  return {
    showWish
  }
}

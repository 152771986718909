import { ApiCache } from 'public/src/pre_requests/modules/ApiCache/index.js'
import {
  getLanguageByKeyApi,
  getHomeDataApi,
  getCategoryDataApi,
  getMyApplicationPageDataApi,
  getMyReportPageDataApi,
  getWeekRemainChanceApi,
} from './index'
import { freeTrialLanguage } from '../utils/languageKeys'

export const commonContextApiCache = new ApiCache({
  cacheTime: 10 * 60 * 1000, // 缓存时间 10 分钟
  request: async params => {
    return await getLanguageByKeyApi({
      languageKeys: freeTrialLanguage,
      ...params,
    })
  },
})

export const homeDataApiCache = new ApiCache({
  cacheTime: 10 * 1000, // 缓存时间 10 秒
  request: async params => {
    return await getHomeDataApi(params)
  },
})

export const homeCategoryApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async params => {
    return await getCategoryDataApi(params)
  },
})

export const myApplicationApiCache = new ApiCache({
  cacheTime: 10 * 1000, // 缓存时间 10 秒
  request: async params => {
    return await getMyApplicationPageDataApi(params)
  },
})

export const myReportApiCache = new ApiCache({
  cacheTime: 10 * 1000, // 缓存时间 10 秒
  request: async params => {
    return await getMyReportPageDataApi(params)
  },
})

export const myWeekRemainChanceApiCache = new ApiCache({
  cacheTime: 10 * 1000, // 缓存时间 10 秒
  request: async params => {
    return await getWeekRemainChanceApi(params)
  },
})

import { PAGE_NAMES } from '@freeTrial/utils/constants'
import { isSwitchBffSsr } from 'public/src/pages/common/bff-new/index.js'
import {
  commonContextApiCache,
  homeDataApiCache,
  homeCategoryApiCache,
  myApplicationApiCache,
  myReportApiCache,
  myWeekRemainChanceApiCache,
} from '@freeTrial/api/apiCache'

const freeTrialCenterRoutes = [
  {
    path: '/free-trial-center/index',
    name: PAGE_NAMES.FREE_TRIAL_CENTER,
    component: () =>
      import(
        /* webpackChunkName: "freeTrialHome" */ '@freeTrial/child_pages/home/FreeTrialHome.vue'
      ),
    meta: {
      titleLanguageKey: 'SHEIN_KEY_PWA_16268',
      showHelp: true,
      showMyTrial: true,
      notNeedCommonHeader: true,
      keepAlive: true,
    },
  },
  {
    path: '/free-trial-center/next-notice',
    name: PAGE_NAMES.NEXT_NOTICE,
    component: () =>
      import(
        /* webpackChunkName: "nextNoticeList" */ '@freeTrial/child_pages/list/NextNoticeList.vue'
      ),
    meta: {
      titleLanguageKey: 'SHEIN_KEY_PWA_16247',
      showHelp: true,
      showMyTrial: true,
      notNeedCommonHeader: true,
      keepAlive: true,
    },
  },
  {
    path: '/free-trial-center/over',
    name: PAGE_NAMES.ENDED,
    component: () =>
      import(
        /* webpackChunkName: "endedList" */ '@freeTrial/child_pages/list/EndedList.vue'
      ),
    meta: {
      titleLanguageKey: 'SHEIN_KEY_PWA_15056',
      showHelp: true,
      showMyTrial: true,
      notNeedCommonHeader: true,
      keepAlive: true,
    },
  },
  {
    path: '/free-trial-center/report',
    name: PAGE_NAMES.TRIAL_REPORT,
    component: () =>
      import(
        /* webpackChunkName: "TrialReportList" */ '@freeTrial/child_pages/list/TrialReportList.vue'
      ),
    meta: {
      titleLanguageKey: 'SHEIN_KEY_PWA_15207',
      showHelp: true,
      showMyTrial: true,
      notNeedCommonHeader: true,
      keepAlive: true,
    },
  },
  {
    path: '/free-trial-center/my-center',
    name: PAGE_NAMES.MY_FREE_TRIAL,
    component: () =>
      import(
        /* webpackChunkName: "MyFreeTrial" */ '@freeTrial/child_pages/user/MyFreeTrial.vue'
      ),
    meta: {
      titleLanguageKey: 'SHEIN_KEY_PWA_16225',
      notNeedCommonHeader: true,
      keepAlive: true,
    },
  },
  {
    path: '/free-trial-center/report-detail/:reportId',
    name: PAGE_NAMES.REPORT_DETAIL,
    component: () =>
      import(
        /* webpackChunkName: "ReportDetail" */ '@freeTrial/child_pages/detail/ReportDetail.vue'
      ),
    meta: {
      titleLanguageKey: 'SHEIN_KEY_PWA_15207',
      notNeedCommonHeader: true,
      keepAlive: true,
    },
  },
  {
    path: '/free-trial-center/post-report',
    name: PAGE_NAMES.UPLOAD_REPORT,
    component: () =>
      import(
        /* webpackChunkName: "ReportDetail" */ '@freeTrial/child_pages/upload/UploadReport.vue'
      ),
    meta: {
      titleLanguageKey: 'SHEIN_KEY_PWA_16228',
      notNeedCommonHeader: true,
    },
  },
]

const freeTrialRoutes = [
  {
    path: '/free-trial-center/index',
    name: 'home',
    meta: {
      isCssInVue: true,
      code: 'home',
      topHeaderShowRight: true,
      titleLanguageKey: 'SHEIN_KEY_PWA_16268',
      keepAlive: true,
      toSavePosition: [
        'next-notice',
        'over',
        'report',
        'report-detail',
        'address-select',
        'my-center',
        'page_goods_detail',
      ],
      notNeedCommonHeader: true,
      pageType: 'freeTrial',
      monitorTag: 'free-trial-center',
    },
    component: () =>
      import(
        /* webpackChunkName: "free-trial-index" */ '../../free_trial_center/child-page/home.vue'
      ),
  },
  {
    path: '/free-trial-center/next-notice',
    name: 'next-notice',
    meta: {
      isCssInVue: true,
      code: 'next-notice',
      topHeaderShowRight: false,
      titleLanguageKey: 'SHEIN_KEY_PWA_16247',
      keepAlive: true,
      notNeedCommonHeader: true,
      toSavePosition: ['page_goods_detail'],
    },
    component: () =>
      import(
        /* webpackChunkName: "free-trial-next-notice" */ '../../free_trial_center/child-page/next-notice.vue'
      ),
  },
  {
    path: '/free-trial-center/over',
    name: 'over',
    meta: {
      isCssInVue: true,
      code: 'over',
      topHeaderShowRight: false,
      titleLanguageKey: 'SHEIN_KEY_PWA_15056',
      keepAlive: true,
      notNeedCommonHeader: true,
      toSavePosition: ['page_goods_detail'],
    },
    component: () =>
      import(
        /* webpackChunkName: "free-trial-over" */ '../../free_trial_center/child-page/over.vue'
      ),
  },
  {
    path: '/free-trial-center/report',
    name: 'report',
    meta: {
      isCssInVue: true,
      code: 'report',
      topHeaderShowRight: false,
      titleLanguageKey: 'SHEIN_KEY_PWA_15207',
      keepAlive: true,
      toSavePosition: ['report-detail', 'page_goods_detail'],
      notNeedCommonHeader: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "free-trial-report" */ '../../free_trial_center/child-page/report.vue'
      ),
  },
  {
    path: '/free-trial-center/report-detail/:report_id',
    name: 'report-detail',
    meta: {
      isCssInVue: true,
      code: 'report-detail',
      topHeaderShowRight: false,
      titleLanguageKey: 'SHEIN_KEY_PWA_15207',
      keepAlive: false,
      notNeedCommonHeader: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "free-trial-report-detail" */ '../../free_trial_center/child-page/report-detail.vue'
      ),
  },
  {
    path: '/free-trial-center/address-select',
    name: 'address-select',
    meta: {
      isCssInVue: true,
      code: 'address-select',
      topHeaderShowRight: false,
      titleLanguageKey: 'SHEIN_KEY_PWA_15670',
      keepAlive: true,
      notNeedCommonHeader: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "free-trial-address-select" */ '../../free_trial_center/child-page/address-select.vue'
      ),
  },
  {
    path: '/free-trial-center/my-center',
    name: 'my-center',
    meta: {
      isCssInVue: true,
      code: 'my-center',
      topHeaderShowRight: false,
      titleLanguageKey: 'SHEIN_KEY_PWA_16225',
      keepAlive: false,
      notNeedCommonHeader: true,
      // toSavePosition: ['post-report']
    },
    component: () =>
      import(
        /* webpackChunkName: "free-trial-my-center" */ '../../free_trial_center/child-page/my-center.vue'
      ),
  },
  {
    path: '/free-trial-center/post-report',
    name: 'post-report',
    meta: {
      isCssInVue: true,
      code: 'post-report',
      topHeaderShowRight: false,
      titleLanguageKey: 'SHEIN_KEY_PWA_16228',
      keepAlive: false,
      notNeedCommonHeader: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "free-trial-post-report" */ '../../free_trial_center/child-page/post-report.vue'
      ),
  },
]

const preFeatchData = (to) => {
  const _gbCommonInfo = typeof gbCommonInfo === 'undefined' ? {} : gbCommonInfo
  commonContextApiCache.request({ lang: _gbCommonInfo.lang })
  if (to.name === PAGE_NAMES.FREE_TRIAL_CENTER) {
    homeDataApiCache.request()
    homeCategoryApiCache.request({ type: 2 })
  }
  if (to.name === PAGE_NAMES.MY_FREE_TRIAL) {
    myApplicationApiCache.request({ page: 1, pageSize: 10 })
    myReportApiCache.request({ page: 1, pageSize: 10 })
    myWeekRemainChanceApiCache.request()
  }
}
export const createFreeTrialCenterRoutes = langPath => {
  const useBffApi = isSwitchBffSsr(['freeTrialCenter_v1'])?.['freeTrialCenter_v1']
  const routes = useBffApi ? freeTrialCenterRoutes : freeTrialRoutes
  routes.forEach(route => {
    route.path = `${langPath}${route.path}`
    if (useBffApi) {
      // 预请求公共数据
      route.beforeEnter = (to, from, next) => {
        if (
          [PAGE_NAMES.FREE_TRIAL_CENTER, PAGE_NAMES.MY_FREE_TRIAL].includes(to.name) && 
          !route.meta.loaded
        ) {
          preFeatchData(to)
        }
        route.meta.loaded = true
        next()
      }
      return
    }
    route.meta.pageType = 'freeTrial'
  })
  return routes
}

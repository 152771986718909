import { ref, computed } from 'vue'
import { useMapGetters, useMapMutations, useMapActions } from './useVuex.js'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import schttp from 'public/src/services/schttp'
import { daEventCenter } from 'public/src/services/eventCenter'

// 是否展示过关注成功弹窗的key
const BEEN_POPUP_SHOW_KEY = 'beenPopupStoreFollowSuccess'

function useFollow({ handleUnFollowDialog } = {}) {
  const { 
    storeData,
    language,
    isFollowed,
    storeUnFollowDialogAbt,
    isShowFirstFollowSuccPopup,
  } = useMapGetters([
    'storeData',
    'language',
    'isFollowed',
    'storeUnFollowDialogAbt',
    'isShowFirstFollowSuccPopup',
  ])
  const { updateCouponData } = useMapActions(['updateCouponData'])
  const { 
    updateIsFollowed,
    updateFirstFollowSuccPopup,
  } = useMapMutations([
    'updateIsFollowed',
    'updateFirstFollowSuccPopup',
  ])

  const storeCode = computed(() => storeData.value.storeCode)
  const signboard = computed(() => storeData.value.signboard || {})
  const followSlot = computed(() => storeData.value.followSlot)
  
  const loading = ref(false)

  const followAction = async () => {
    // 取消关注挽留弹窗
    if (storeUnFollowDialogAbt.value === 'B' && isFollowed.value && typeof handleUnFollowDialog === 'function') {
      try {
        daEventCenter.triggerNotice({
          daId: '2-22-85',
          bindData: {}
        })
        await handleUnFollowDialog()
        // 上报confirm埋点
        daEventCenter.triggerNotice({
          daId: '2-22-86',
          bindData: {
            customParams: {
              position: 'confirm'
            }
          }
        })
      } catch (error) {
        // 上报cancel埋点
        daEventCenter.triggerNotice({
          daId: '2-22-86',
          bindData: {
            customParams: {
              position: 'cancel'
            }
          }
        })
        return
      }
    }

    if (loading.value) return
    loading.value = true
    const action = !isFollowed.value ? 'follow' : 'unfollow'
    try {
      const result = await schttp({
        url: '/product/store/update_following',
        method: 'POST',
        data: {
          action,
          scene: 'store',
          store_code_list: storeCode.value,
        },
        useBffApi: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      const { code } = result
      if (code === '0') {
        updateIsFollowed(!isFollowed.value)
        if (action === 'follow') {
          if (localStorage?.getItem(BEEN_POPUP_SHOW_KEY) === '1') {
            SToast({
              content: language.value.SHEIN_KEY_PWA_24070,
              duration: 3000,
            })
          } else {
            // 第一次提示
            updateFirstFollowSuccPopup(true)
            localStorage?.setItem(BEEN_POPUP_SHOW_KEY, '1')
          }
        } else {
          SToast({
            content: language.value.SHEIN_KEY_PWA_24074,
            duration: 3000,
          })
        }
      } else if (String(code) === '00101110') { // 未登录
        if (typeof window !== 'undefined' && typeof SHEIN_LOGIN !== 'undefined') {
          const loginCallback = state => {
            if (String(state?.code) === '0') {
              followAction()
              updateCouponData() // 更新券信息
            }
          }
          SHEIN_LOGIN.show({
            show: true,
            bi: 'other',
            cb: loginCallback
          })
        }
      } else if (code === '400429') { // 关注失败，关注数量超出上限
        SToast({
          content: language.value.SHEIN_KEY_PWA_24077,
          duration: 3000,
        })
      } else {
        throw result
      }
    } catch (err) {
      SToast({
        content: action === 'follow' ? language.value.SHEIN_KEY_PWA_24076 : language.value.SHEIN_KEY_PWA_24075,
        duration: 3000,
      })
    }
    // eslint-disable-next-line require-atomic-updates
    loading.value = false
  }

  return {
    isShowFirstFollowSuccPopup,
    isFollowed,
    storeCode,
    signboard,
    language,
    followAction,
    updateFirstFollowSuccPopup,
    followSlot,
  }
}

export default useFollow

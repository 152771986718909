function getComponentName(Component) {
  return Component && (Component.name || Component.__name)
}

// 当路由有嵌套时的keepAlive的处理，确保都可以缓存
function getMatchedRouteList(matchedRoutes, lastMatchedRoute) {
  // 多层嵌套路由需要缓存的组件名
  const keepAliveMap = {
    UserIndex: ['PageContainer', 'UserIndex'],
  }
  const { meta, name } = lastMatchedRoute
  const matchedKeepAliveConfig = keepAliveMap[name]
  if (matchedRoutes.length > 1 && lastMatchedRoute.meta.keepAlive && !!matchedKeepAliveConfig) {
    return matchedKeepAliveConfig
  }
  return null
}

export default {
  data() {
    return {
      // 用于 `<keep-alive>` 的组件名称列表
      keepAliveIncludes: [],
    }
  },
  watch: {
    // 监视路径变化，来更新需要 keepAlive 的组件列表
    '$route.path': {
      immediate: true,
      handler() {
        const route = this.$route
        // 确保有匹配的路由记录，并检查路由元信息以决定是否需要缓存
        if (route.matched?.length === 0 || !route.meta?.keepAlive) {
          return
        }
        // 获取最末尾的路由记录，即当前页面的路由
        const lastMatchedRecord = route.matched[route.matched.length - 1]
        const Component = lastMatchedRecord?.components?.default
        const name = getComponentName(Component)
        if (!name) {
          console.warn('当前组件没有name属性, 将不会被缓存')
          return
        }
        if (!this.keepAliveIncludes.includes(name)) {
          const matchedList = getMatchedRouteList(route.matched, lastMatchedRecord)
          if (matchedList?.length > 0) {
            this.keepAliveIncludes.push(...matchedList)
            return
          }
          this.keepAliveIncludes.push(name)
        }
      },
    },
  },
}

let isFirstPageLoad = true

function useOriginUserRouteHook(router) {
  const { host, langPath } = gbCommonInfo
  let user = gbCommonInfo.user
  
  // 从 Vue.mixin beforeRouteLeave 迁移的钩子
  router.beforeEach((to, from, next) => {
    // 只在路由离开时处理逻辑，首次进入时跳过
    if (isFirstPageLoad) {
      isFirstPageLoad = false
      return next()
    }
    user = gbCommonInfo.user
  
    if (!from.meta.noNeedEmitPageUnload) {
      appEventCenter.emit('pageUnload')
    }

    if (to.meta && to.meta.needLogin && user && !user.member_id) {
      const links = ['AddressBook', 'UserWallet', 'UserOrdersList', 'Coupon', 'Payment', 'Tickets', 'UserSurvey',
        'StdDiscountInfo', 'StdDiscountResult', 'StdDiscountValidate', 'UserOrdersDetail', 'FriendshipCouponEarnedPoints', 'Prime', 'UserServicePhone']
      const redirectUrl = links.indexOf(to.name) > -1 ? to.path : '/user/index'
      location.href = host + langPath + '/user/login?redirection=' + encodeURIComponent(redirectUrl)
    } else {
      next()
    }
  })
  
  router.afterEach((to) => {
    user = gbCommonInfo.user
  
    if (to.meta && to.meta.needLogin && user && !user.member_id) {
      const redirectUrl = to.path + location.search
      location.href = host + langPath + '/user/login?redirection=' + encodeURIComponent(redirectUrl)
    } else {
      appEventCenter.emit('checkBlackListUser')
      if (window.JOURNEY_BRANCH && !['productList', 'page_store'].includes(to.meta.pageType)) {
        window.JOURNEY_BRANCH.pageView(to)
      }
      if (to.meta.pageType == 'userPage' && to.name == 'UserIndex') {
        window.scrollTo(0, 0)
      }
    }
  })
}

export { useOriginUserRouteHook }

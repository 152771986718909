export const PAGE_NAMES = {
  // 主页
  FREE_TRIAL_CENTER: 'page_free_trial',
  // 正在进行
  IN_PROGRESS: 'page_in_progress',
  // 下期预告
  NEXT_NOTICE: 'page_next_notice',
  // 结束
  ENDED: 'page_over',
  // 试用列表
  TRIAL_REPORT: 'page_trial_report',
  // 试用个人中心
  MY_FREE_TRIAL: 'page_trialcenter',
  // 上传试用报告
  UPLOAD_REPORT: 'page_upload_report',
  // 试用报告详情
  REPORT_DETAIL: 'page_trial_report_detail',
}

// 举报成功后的report id
export const FEEDBACK_REPORTS_CACHE_KEY = 'feedback_reports_cache_key'

// 试用报告提交成功后的缓存key
export const SUBMIT_REPORT_SUCCESS_CACHE_KEY = 'submit_report_success_cache_key'

// 试用报告详情缓存key
export const POST_REPORT_INFO_CACHE_KEY = 'post_report_info_cache_key'

// 试用中心申请试用地址缓存key
export const FREE_TRIAL_ADDRESS_CACHE_KEY = 'free_trial_address_cache_key'

import { createApp } from 'vue'

class GroupDrawer {
  constructor() {
    this.compInstance = null
  }

  async use(config, cb) {
    if (!this.compInstance) {
      const component = await import(/* webpackChunkName: "wishlist_group_drawer" */ './index.vue')

      const appDom = document.createElement('div')
      const app = createApp(component.default)
      const vm = app.mount(appDom)
      document.body.appendChild(vm.$el)
      const comp = vm
      this.compInstance = comp
    }
    
    return await this.compInstance.use(config, cb)
  }
}

export default (...arg) => {
  const groupDrawer = new GroupDrawer()
  return groupDrawer.use(...arg)
}

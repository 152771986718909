import { computed, onMounted } from 'vue'
import { useGlobalMapState } from '../hooks/store.js'
import ControlAsync from '../analysis/controlAsync.js'

export default function useShare({ route }) {
  const { goodsDetailInfo } = useGlobalMapState(['goodsDetailInfo'])

  const showShare = computed(() =>{
    //详情页分享
    return ['page_goods_detail'].includes(
      route.value.name
    )
  })

  const headerAnalysis = new ControlAsync({
    file: () => import(/* webpackChunkName: "header-analysis" */ '../analysis/index.js')
  }).run()


  const handleShare = () => {
    headerAnalysis().then(res => {
      res.clickShare(goodsDetailInfo.value)
    })
    window.vBus && window.vBus.emit('handleHeaderShareForGoodsDetail')
  }

  onMounted(() => {
    headerAnalysis().then((res) => res.exposeShare(goodsDetailInfo.value))
  })

  return {
    showShare,
    handleShare,
  }
}

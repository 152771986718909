import { createSSRApp } from 'vue'
import { createRouter } from './router'
import { createStore } from './store/index'
import { sync } from './util/vuex-router-sync.js'
import App from './App.vue'
import { createHead } from '@unhead/vue'
import { HydrateRouterView } from 'public/src/spaHydration'
import { useSaDirective } from '@shein-aidc/basis-sa-event-center'

/**
 *
 * @param {渲染需要的上下文} context
 * @param {客户端或者服务端分别 传入的router、minx、func} params
 */
function createApp (context = {}, params) {
  const app = createSSRApp(App)
  const store = createStore(context)
  const router = createRouter(context, { ...params, store }, app)
  // const { mixin } = params
  // mshePrefetch.watch(router)
  // 同步路由状态(route state)到 store
  sync(store, router)
  // if (mixin) app.mixin(mixin)
  app.use(store)
  app.use(router)
  app.use(createHead())
  app.use(HydrateRouterView())
  
  useSaDirective(app)
  
  return {
    app,
    router,
    store
  }
}

export default createApp

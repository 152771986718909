<template>
  <div class="store-header__right">
    <!-- 没有吸顶 -->
    <StoreHeaderMore 
      v-show="!isStoreTopTabSticky"
    />
    <!-- 命中abt -->
    <ClientOnlySlot 
      v-if="navBarFollowButtonType"
    >
      <template 
        v-if="isShowFollowIcon"
      >
        <StoreFollowIconBff 
          v-show="isStoreTopTabSticky"
        />
      </template>
      <StoreFollowEntry 
        v-else
        v-show="isStoreTopTabSticky"
      />
    </ClientOnlySlot>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StoreHeaderCenter', // 店铺中间
})
</script>
<script setup>
import {  computed } from 'vue'
import ClientOnlySlot from 'public/src/pages/components/ccc/base/ClientOnlySlot.vue'
import { useStorePageData, useInjectStoreHeader } from '../../hooks/useStoreHeader.js'
import StoreHeaderMore from './StoreHeaderMore.vue'
import StoreFollowIconBff from './StoreFollowIconBff.vue'
import StoreFollowEntry from './StoreFollowEntry.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'

daEventCenter.addSubscriber({ modulecode: '2-9' })
daEventCenter.addSubscriber({ modulecode: '2-22' }) 

const { isStoreTopTabSticky } = useInjectStoreHeader()

const { storeData } = useStorePageData()

/**
 * 店铺页右上角navbar状态。1: 展示关注页入口; 2: 展示关注按钮; 其他: 保持线上逻辑
 * pageId=1468079770
 */
const navBarFollowButtonType = computed(()=>{
  return storeData.value?.navBarFollowButtonType
})
const isShowFollowIcon = computed(()=>{
  return navBarFollowButtonType.value === '2'
})

</script>
<style lang="less">
.store-header__right{
  flex-shrink: 0;
}

</style>

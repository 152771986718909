const language = {
  'header_lang': [
    'SHEIN_KEY_PWA_15735',
    'SHEIN_KEY_PWA_15792',
    'SHEIN_KEY_PWA_16145',
    'SHEIN_KEY_PWA_16134',
    'SHEIN_KEY_PWA_20047',
    'SHEIN_KEY_PWA_24171',
    'SHEIN_KEY_PWA_24172',
    'SHEIN_KEY_PWA_24173',
    'SHEIN_KEY_PWA_24178',
    'SHEIN_KEY_PWA_15146',
    'SHEIN_KEY_PWA_26773',
    'SHEIN_KEY_PWA_27120',
    'SHEIN_KEY_PWA_27121',
    'SHEIN_KEY_PWA_31032',
    'SHEIN_KEY_PWA_27300',
    'SHEIN_KEY_PWA_30497',
    'SHEIN_KEY_PWA_32912',
    'SHEIN_KEY_PWA_32913',
    'SHEIN_KEY_PWA_34222',
    'SHEIN_KEY_PWA_34223'
  ],
  'index_lang': [
    'SHEIN_KEY_PWA_14980',
    'SHEIN_KEY_PWA_15682',
    'SHEIN_KEY_PWA_15683',
    'SHEIN_KEY_PWA_15680'
  ],
  'list_lang': [
    'SHEIN_KEY_PWA_17597',
    'SHEIN_KEY_PWA_17598',
    'SHEIN_KEY_PWA_16039',
    'SHEIN_KEY_PWA_16784',
    'SHEIN_KEY_PWA_16785',
    'SHEIN_KEY_PWA_16696',
    'SHEIN_KEY_PWA_16837',
    'SHEIN_KEY_PWA_17239',
    'SHEIN_KEY_PWA_17234',
    'SHEIN_KEY_PWA_17235',
    'SHEIN_KEY_PWA_17236',
    'SHEIN_KEY_PWA_17237',
    'SHEIN_KEY_PWA_17238',
    'SHEIN_KEY_PWA_17599',
    'SHEIN_KEY_PWA_15679',
    'SHEIN_KEY_PWA_16805',
    'SHEIN_KEY_PWA_17600',
    'SHEIN_KEY_PWA_17595',
    'SHEIN_KEY_PWA_15681',
    'SHEIN_KEY_PWA_17601',
    'SHEIN_KEY_PWA_17602',
    'SHEIN_KEY_PWA_15678',
    'SHEIN_KEY_PWA_15045',
    'SHEIN_KEY_PWA_17603',
    'SHEIN_KEY_PWA_17604',
    'SHEIN_KEY_PWA_17605',
    'SHEIN_KEY_PWA_17594',
    'SHEIN_KEY_PWA_17596',
    'SHEIN_KEY_PWA_17606',
    'SHEIN_KEY_PWA_17607',
    'SHEIN_KEY_PWA_17614',
    'SHEIN_KEY_PWA_17615',
    'SHEIN_KEY_PWA_17616',
    'SHEIN_KEY_PWA_17617',
    'SHEIN_KEY_PWA_17618',
    'SHEIN_KEY_PWA_17619',
    'SHEIN_KEY_PWA_17620',
    'SHEIN_KEY_PWA_17621',
    'SHEIN_KEY_PWA_17622',
    'SHEIN_KEY_PWA_17623',
    'SHEIN_KEY_PWA_17624',
    'SHEIN_KEY_PWA_17625',
    'SHEIN_KEY_PWA_17626',
    'SHEIN_KEY_PWA_17627',
    'SHEIN_KEY_PWA_17628',
    'SHEIN_KEY_PWA_17629',
    'SHEIN_KEY_PWA_17630',
    'SHEIN_KEY_PWA_17631',
    'SHEIN_KEY_PWA_17593',
    'SHEIN_KEY_PWA_17592',
    'SHEIN_KEY_PWA_17591',
    'SHEIN_KEY_PWA_17590',
    'SHEIN_KEY_PWA_17589',
    'SHEIN_KEY_PWA_17588',
    'SHEIN_KEY_PWA_17587',
    'SHEIN_KEY_PWA_17586',
    'SHEIN_KEY_PWA_17585',
    'SHEIN_KEY_PWA_17584',
    'SHEIN_KEY_PWA_17583',
    'SHEIN_KEY_PWA_19524',
    'SHEIN_KEY_PWA_19658',
    'SHEIN_KEY_PWA_19519',
    'SHEIN_KEY_PWA_20827',
    'SHEIN_KEY_PWA_20828',
    'SHEIN_KEY_PWA_20829',
    'SHEIN_KEY_PWA_20830',
    'SHEIN_KEY_PWA_20831',
    'SHEIN_KEY_PWA_20832',
    'SHEIN_KEY_PWA_20833',
    'SHEIN_KEY_PWA_20834',
    'SHEIN_KEY_PWA_20835',
    'SHEIN_KEY_PWA_20836',
    'SHEIN_KEY_PWA_20838',
    'SHEIN_KEY_PWA_20840',
    'SHEIN_KEY_PWA_20841',
    'SHEIN_KEY_PWA_20842',
    'SHEIN_KEY_PWA_20843',
    'SHEIN_KEY_PWA_20844',
    'SHEIN_KEY_PWA_20845',
    'SHEIN_KEY_PWA_20846',
    'SHEIN_KEY_PWA_20847',
    'SHEIN_KEY_PWA_20848',
    'SHEIN_KEY_PWA_20849',
    'SHEIN_KEY_PWA_20850',
    'SHEIN_KEY_PWA_20851',
    'SHEIN_KEY_PWA_20852',
    'SHEIN_KEY_PWA_20853',
    'SHEIN_KEY_PWA_20854',
    'SHEIN_KEY_PWA_20855',
    'SHEIN_KEY_PWA_20856',
    'SHEIN_KEY_PWA_19698',
    'SHEIN_KEY_PWA_19520',
    'SHEIN_KEY_PWA_19518',
    'SHEIN_KEY_PWA_17771',
    'SHEIN_KEY_PWA_18418',
    'SHEIN_KEY_PWA_18417',
    'SHEIN_KEY_PWA_18416',
    'SHEIN_KEY_PWA_18415',
    'SHEIN_KEY_PWA_18414',
    'SHEIN_KEY_PWA_18413',
    'SHEIN_KEY_PWA_18412',
    'SHEIN_KEY_PWA_18411',
    'SHEIN_KEY_PWA_18410',
    'SHEIN_KEY_PWA_18409',
    'SHEIN_KEY_PWA_18547',
    'SHEIN_KEY_PWA_19089',
    'SHEIN_KEY_PWA_23768',
    'SHEIN_KEY_PWA_24324',
    'SHEIN_KEY_PWA_24325',
    'SHEIN_KEY_PWA_24326',
    'SHEIN_KEY_PWA_24327',
    'SHEIN_KEY_PWA_24658',
    'SHEIN_KEY_PWA_24659',
    'SHEIN_KEY_PWA_24660',
    'SHEIN_KEY_PWA_14840',
    'SHEIN_KEY_PWA_26149',
    'SHEIN_KEY_PWA_25446',
    'SHEIN_KEY_PWA_25447',
    'SHEIN_KEY_PWA_27272',
    'SHEIN_KEY_PWA_24718',
    'SHEIN_KEY_PWA_28240',
    'SHEIN_KEY_PWA_15623',
    'SHEIN_KEY_PWA_30904',
  ],
  'ac_color_lang': [
    'SHEIN_KEY_PWA_16804'
  ],
  'member_lang': [
    'SHEIN_KEY_PWA_15187',
    'SHEIN_KEY_PWA_15713'
  ],
  'goods_lang': [
    'SHEIN_KEY_PWA_15046',
    'SHEIN_KEY_PWA_15042'
  ],
  'category_lang': [
    'SHEIN_KEY_PWA_15662'
  ],
  'presearch_lang':[
    'SHEIN_KEY_PWA_37280',
  ]
}
  
const languageKeys = Object.values(language).flat()

export default languageKeys

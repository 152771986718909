import schttp from 'public/src/services/schttp'
import { preloadImg } from 'public/src/pages/ccc-campaigns/components/common/utils.js'

async function fetchCartContentInfoApi() {
  const res = await (window.fetchCartContentInfo || schttp({ url: `/api/cart/get?prefetch=1`, headers: { 'x-sph': '1' } }))
  const { contextForSSR, ssrHTML, ssrVueCSS } = res || {}
  if (!contextForSSR) throw new Error('fetchCartContentInfo failed')
  // eslint-disable-next-line require-atomic-updates
  window.cartSsrData = contextForSSR
  if (ssrHTML && ssrVueCSS) {
    let goodsImgs
    try {
      const imgs = Array.from(document.createRange().createContextualFragment(ssrHTML).querySelectorAll('#cart-app .j-cart-main-img')).map(_ => _.getAttribute('src')).filter(_ => _)
      if (imgs.length) {
        goodsImgs = preloadImg([... new Set(imgs)], null, 1000)
      }
    } catch (error) {
      console.error(error)
    }

    return {
      html: ssrHTML,
      style: ssrVueCSS || '',
      contextForSSR,
      goodsImgs
    }
  }
  return {
    contextForSSR
  }
}

const spaCart = {
  // 页面路由name
  routeName: 'cart',
  /**
 * @param {Object} param
 * @returns {Promise<{html: string, style: string, ...}>}
*/
  request: async function () {
    try {
      return await fetchCartContentInfoApi()
    } catch (error) {
      location.reload()
    }
  },
  // 调度器：上屏html后，回调控制组件的渲染
  scheduler: async (result, fn) => {
    const { goodsImgs } = result?.data || {}
    requestAnimationFrame(() => { // 上屏前
      goodsImgs
        ? goodsImgs.then(() => requestAnimationFrame(() => fn()))
        : requestAnimationFrame(() => fn())
    })
  },
}

export default spaCart

<script lang="jsx">
import { defineComponent } from 'vue'
const paths = ['M21 4.5V6H3V4.5H21Z', 'M21 11.25V12.75H3V11.25H21Z', 'M21 19.5V18H3V19.5H21Z']

export default defineComponent({
  name: 'BHeaderNavIcon',

  props: {
    size: {
      type: [Number, String],
      default: 24,
    },
  },
  render({ $props: props, $attrs: listeners }) {
    const style = `vertical-align: middle; display: inline-block; line-height: 0;fontSize: ${props.size}px`
    return (
      <span
        style={style}
        onClick={listeners?.onClick}
      >
        <svg
          style="width: auto; height: 1em"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {
            paths.map(d => {
              return <path fill-rule="evenodd" clip-rule="evenodd" d={d} fill="var(--shein-common-header-icon-color, #000)"></path>
            })
          }
        </svg>
      </span>
    )
  },
})
</script>

import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
// import { preloadImg } from '../../ccc-campaigns/components/common/utils'
import { triggerHydrationSsrRequest } from 'public/src/spaHydration/index.js'

const { langPath } = gbCommonInfo

const checkoutRoutes = [
  {
    path: `${langPath}/checkout`,
    name: 'checkout',
    meta: {
      isCssInVue: true,
      keepAlive: false,
      notNeedCommonHeader: true,
      pageType: 'checkout',
      monitorTag: 'check-out'
    },
    component: prefetchResource.importAsyncComponent({
      chunkName: 'checkout_app', // 跟 webpackChunkName 保持一致
      componentFactory: () => import( /* webpackChunkName: 'checkout_app' */ '../../checkout_new/App.vue')
    }),
    beforeEnter: (to, from, next) => {
      if (from.name && from.name !== 'checkout') {
        if (!window.checkoutPending) return location.reload()
        triggerHydrationSsrRequest('checkout')
        window.checkoutPending = null
        window.checkoutWithCouponCachePending = null
        next()
      } else {
        next()
      }
    },
  },
  {
    path: `${langPath}/checkout/guest_first_address`,
    name: 'guest_first_address',
    meta: {
      isCssInVue: true,
      keepAlive: true,
      noNeedEmitPageUnload: true,
      notNeedCommonHeader: true,
      pageType: 'first_address'
    },
    redirect() {
      location.href = `${gbCommonInfo.langPath}/404.html`
    },
  },
  {
    path: `${langPath}/checkout/first_address`,
    name: 'first_address',
    meta: {
      isCssInVue: true,
      keepAlive: true,
      noNeedEmitPageUnload: true,
      notNeedCommonHeader: true,
      pageType: 'first_address'
    },
    component: () => import(/* webpackChunkName: "checkout_first_address" */'../../checkout/first_address/container.vue')
  },
  {
    path: `${langPath}/checkout/blik_code_way`,
    name: 'blik_code_way',
    meta: {
      isCssInVue: true,
      keepAlive: false,
      notNeedCommonHeader: true,
      pageType: 'blik_code_way'
    },
    component: () => import(/* webpackChunkName: "checkout_blik_code_way" */'../../checkout/blik_code_way/container.vue')
  },
  {
    path: `${langPath}/checkout/paidy`,
    name: 'checkout-paidy-paylater',
    meta: {
      isCssInVue: true,
      keepAlive: false,
      notNeedCommonHeader: true,
      pageType: 'checkout-paidy-paylater'
    },
    component: () => import(/* webpackChunkName: "checkout-paidy-paylater" */'../../checkout/paidy/container.vue')
  },
  {
    path: `${langPath}/checkout/middlePage`,
    name: 'checkout-middle-page',
    meta: {
      isCssInVue: true,
      keepAlive: false,
      notNeedCommonHeader: true,
      pageType: 'checkout-middle-page'
    },
    component: () => import(/* webpackChunkName: "checkout-middle-page" */'../../checkout/middlePage/container.vue')
  }
]

export {
  checkoutRoutes
}


import { getPageCommonDataCache, getPageDataCache, getFiltersDataCache } from 'public/src/pages/choices/api'
export const createChoicesRoute = (langPath = '') => {
  return {
    path: `${langPath}/choices-landing`,
    name: 'choices_landing',
    meta: {
      keepAlive: true,
      pageType: 'choices_landing',
      monitorTag: 'choices_landing',
      isCssInVue: true,
      notNeedCommonHeader: true,
    },
    component: () => import( /* webpackChunkName: "choices" */ '../../choices/App.vue'),
    beforeEnter: (to, from, next) => {
      if(typeof window !== 'undefined'){
        if(from.name && from.name !== 'choices_landing'){
          getPageCommonDataCache()
          getPageDataCache(to.query)
          getFiltersDataCache(to.query)
        }
      }
      next()
    },
  }
}

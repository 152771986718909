import { createRouter as _createRouter, createWebHistory, createMemoryHistory } from 'vue-router'
import { EventBus } from '../../common/event-bus'
import { createMatchHydrationRoute, listenGlobalRouteBeforeEnter } from 'public/src/spaHydration/index.js'
import spaHydrationModules from 'public/src/spaHydration/modules/index.js'
import { stringifyQueryString, parseQueryString } from '@shein/common-function'

export function createRouter (context, { routes, clientFunc, store }, app) {
  const isServer = typeof window === 'undefined'
  const router = _createRouter({
    history: isServer ? createMemoryHistory() : createWebHistory(),
    routes: isServer ? routes : createMatchHydrationRoute({ routes, modules: spaHydrationModules, globalConfig: { screenElement: '#prerender-in-app' } }),
    scrollBehavior (to, from, savedPosition) {
      if (to.meta?.ignoreScrollBehavior?.status) {
        delete to.meta.ignoreScrollBehavior.status
        return
      }
      return window?.appRouteExtData?.direction === -1 && to.matched.some(m => m.meta.keepAlive)
        ? to.meta.savedPosition || savedPosition || { left: 0, top: 0 }
        : { left: 0, top: 0 }
    },
    parseQuery: parseQueryString,
    stringifyQuery: (query) => stringifyQueryString({ queryObj: query })
  })
  if (!isServer) {
    listenGlobalRouteBeforeEnter(router)
  }
  // 路由进入
  router.beforeEach((to, from, next) => {
    // 触发router-change事件，给子组件监听
    EventBus.emit('router-change', { to, from, next })
    // for click effect && analysis send
    if (from.meta.isForward) {
      from.meta.isForward = undefined
      next()
    } else {
      next()
    }
  })

  if (clientFunc) clientFunc({ router, store, app })

  return router
}

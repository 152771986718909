import { fetchSpaSsrHydrationApi } from 'public/src/pre_requests/modules/productList/spaSsrHydration/core.js'
import { preloadImg } from 'public/src/pages/ccc-campaigns/components/common/utils.js'
import spaCart from 'public/src/spaHydration/modules/cart.js'
import spaCheckout from 'public/src/spaHydration/modules/checkout.js'

export default [
  {
    // 页面路由name
    routeName: 'product-list-v2',
    /**
     * @param {Object} param
     * @returns {Promise<{html: string, style: string, ...}>}
    */
    request: async function (param) {
      const res = await fetchSpaSsrHydrationApi(param)
      if (!res) return // 没值直接返回空，内部会处理
      const { html, style, contextForSSR, preloadImgs } = res || {}
      return { html, style, contextForSSR, preloadImgs }
    },
    // 调度器：上屏html后，回调控制组件的渲染
    scheduler: async (result, next) => {
      const { preloadImgs } = result.data || {}
      await preloadImg([...new Set(preloadImgs || [])]).catch(() => {})
      requestAnimationFrame(() => {
        requestAnimationFrame(() => next())
      })
    },
  },
  spaCart,
  spaCheckout,
]

import { template } from '@shein/common-function'
import { htmlDecodeCommonFn } from 'public/src/pages/common/utils/index.js'
import '../../user/analysis'
import { transformImg } from '@shein/common-function'

const { PUBLIC_CDN, lang, langPath, host, GB_cssRight, IMG } = gbCommonInfo

// let user = gbCommonInfo.user

const originUserMixin = {
  data () {
    return {
      PUBLIC_CDN,
      lang,
      langPath,
      host,
      GB_cssRight,
      IMG
    }
  },
  /* beforeRouteLeave (to, from, next) {
    // 当路由有嵌套时（即children），beforeRouteEnter根据嵌套数会多次触发，设置变量以免多次触发
    if (window.hasLeaveRoute && window.hasLeaveRoute == 2) return next()
    window.hasLeaveRoute = 2

    user = gbCommonInfo.user

    if (!from.meta.noNeedEmitPageUnload) {
      appEventCenter.emit('pageUnload')
    }

    if (to.meta && to.meta.needLogin && user && !user.member_id) {
      const links = ['AddressBook', 'UserWallet', 'UserOrdersList', 'Coupon', 'Payment', 'Tickets', 'UserSurvey',
        'StdDiscountInfo', 'StdDiscountResult', 'StdDiscountValidate', 'UserOrdersDetail', 'FriendshipCouponEarnedPoints', 'Prime', 'UserServicePhone']
      const redirectUrl = links.indexOf(to.name) > -1 ? to.path : '/user/index'
      location.href = host + langPath + '/user/login?redirection=' + encodeURIComponent(redirectUrl)
    } else {
      next()
    }
  }, */
  /* beforeRouteEnter (to, from, next) {
    // 当路由有嵌套时（即children），beforeRouteEnter根据嵌套数会多次触发，设置变量以免多次触发
    if (window.hasEnterRoute && window.hasEnterRoute == 2) {
      next()
    } else {
      window.hasEnterRoute = 2

      user = gbCommonInfo.user

      if (to.meta && to.meta.needLogin && user && !user.member_id) {
        const redirectUrl = to.path + location.search
        location.href = host + langPath + '/user/login?redirection=' + encodeURIComponent(redirectUrl)
      } else {
        next(vm => {
          appEventCenter.emit('checkBlackListUser')

          if (window.JOURNEY_BRANCH && !['productList', 'page_store'].includes(vm.$route.meta.pageType)) {
            window.JOURNEY_BRANCH.pageView(vm)
          }

          if (to.meta.pageType == 'userPage' && to.name == 'UserIndex') {
            window.scrollTo(0, 0)
          }
        })
      }
    }
  }, */
  methods: {
    transformImg,
    template: template,
    htmlDecode: htmlDecodeCommonFn
  }
}

export default originUserMixin

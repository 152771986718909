import schttp from 'public/src/services/schttp'
import { PAGE_NAMES } from '@freeTrial/utils/constants'

export const getLanguageByKeyApi = async data => {
  let result = {}
  try {
    const response = await schttp({
      method: 'POST',
      url: '/system/configs/multi_language',
      data,
      useBffApi: true,
    })
    result = response?.info?.result || {}
  } catch (error) {
    console.error('getLanguageByKeyApi error:', error)
  }
  return result
}

/**
 * 获取首页数据
 */
export const getHomeDataApi = async params => {
  let result = {}
  try {
    const response = await schttp({
      method: 'GET',
      url: '/user/trial/free_trial_homepage_list',
      params,
      useBffApi: true,
    })
    result = response?.info || {}
  } catch (error) {
    console.error('getHomeDataApi error:', error)
  }
  return result
}

/**
 * 获取inprogress、nextNotice、ended列表数据
 */
export const getPageDataApi = async params => {
  let result = {}
  try {
    const response = await schttp({
      method: 'GET',
      url: '/user/trial/free_trial_list',
      params,
      useBffApi: true,
    })
    result = response || {}
  } catch (error) {
    console.error('getPageDataApi error:', error)
  }
  return result
}

/**
 * 获取freeTrialReport列表数据
 */
export const getTrailReportPageDataApi = async params => {
  let result = {}
  try {
    const response = await schttp({
      method: 'GET',
      url: '/user/trial/free_trial_report_list',
      params,
      useBffApi: true,
    })
    result = response || {}
  } catch (error) {
    console.error('getTrailReportPageDataApi error:', error)
  }
  return result
}

/**
 * 获取分类数据
 */
export const getCategoryDataApi = async params => {
  let result = {}
  try {
    const response = await schttp({
      method: 'GET',
      url: '/user/trial/get_free_trial_cate',
      params,
      useBffApi: true,
    })
    result = response?.info?.categories || []
  } catch (error) {
    console.error('getCategoryDataApi error:', error)
  }
  return result
}

/**
 * 获取报告详情数据
 */
export const getTrailReportDetailApi = async (params, from) => {
  let result = {}
  const url =
    from === PAGE_NAMES.MY_FREE_TRIAL
      ? '/user/trial/member_free_trial_report_show'
      : '/user/trial/free_trial_report_detail'
  try {
    const response = await schttp({
      method: 'GET',
      url,
      params,
      useBffApi: true,
    })
    result = response || {}
  } catch (error) {
    console.error('getTrailReportDetailApi error:', url, error)
  }
  return result
}
/**
 * 获取剩余试用次数
 */
export const getWeekRemainChanceApi = async params => {
  let result = {}
  try {
    const response = await schttp({
      method: 'GET',
      url: '/user/trial/week_remain_chance',
      params,
      useBffApi: true,
    })
    result = response || {}
  } catch (error) {
    console.error('getWeekRemainChanceApi error:', error)
  }
  return result
}

/**
 * 获取My Application列表数据
 */
export const getMyApplicationPageDataApi = async params => {
  let result = {}
  try {
    const response = await schttp({
      method: 'GET',
      url: '/user/trial/member_free_trial_apply_list',
      params,
      useBffApi: true,
    })
    result = response || {}
  } catch (error) {
    console.error('getMyApplicationPageDataApi error:', error)
  }
  return result
}

/**
 * 获取My Report列表数据
 */
export const getMyReportPageDataApi = async params => {
  let result = {}
  try {
    const response = await schttp({
      method: 'GET',
      url: '/user/trial/member_report_list',
      params,
      useBffApi: true,
    })
    result = response || {}
  } catch (error) {
    console.error('getMyReportPageDataApi error:', error)
  }
  return result
}

/**
 * 上传图片
 */
export const uploadImageApi = async data => {
  let result = {}
  try {
    const response = await schttp({
      method: 'POST',
      url: '/user/trial/upload_report_img',
      data,
      useBffApi: true,
    })
    result = response || {}
  } catch (error) {
    console.error('uploadImageApi error:', error)
  }
  return result
}

/**
 * 获取提交报告的尺寸信息
 */
export const getCommonSizeConfigBySkuApi = async params => {
  let result = {}
  try {
    const response = await schttp({
      method: 'GET',
      url: '/order/comment/getCommentSizeConfigBySku',
      params,
      useBffApi: true,
    })
    result = response || {}
  } catch (error) {
    console.error('getCommonSizeConfigBySkuApi error:', error)
  }
  return result
}

/**
 * 新增报告
 */
export const addReportApi = async data => {
  let result = {}
  try {
    const response = await schttp({
      method: 'POST',
      url: '/user/trial/addReport',
      data,
      useBffApi: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    result = response || {}
  } catch (error) {
    console.error('addReportApi error:', error)
  }
  return result
}

/**
 * 编辑报告
 */
export const editReportApi = async data => {
  let result = {}
  try {
    const response = await schttp({
      method: 'POST',
      url: '/user/trial/editReport',
      data,
      useBffApi: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    result = response || {}
  } catch (error) {
    console.error('editReportApi error:', error)
  }
  return result
}

/**
 * 提交免费试用申请
 */
export const freeTrialApplyApi = async data => {
  let result = {}
  try {
    const response = await schttp({
      method: 'POST',
      url: '/user/trial/store_free_trial_apply',
      data,
      useBffApi: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    result = response || {}
  } catch (error) {
    console.error('freeTrialApplyApi error:', error)
  }
  return result
}

import asyncStreamCenter from 'public/src/pages/user/service/asyncStreamCenter'
import {
  CccLink,
  isInSpa,
} from 'public/src/pages/components/ccc/common/ccc-link'
import { SILogger, SIMetric } from 'public/src/pages/common/monitor/index.js'
export const triggerLowPriorityTask = fn => {
  asyncStreamCenter.register(
    () => {
      requestIdleCallback(() => {
        fn()
      })
    },
    { scene: 'dashboard' },
  )
}

export const getLocalStorage = (cacheKey, fallbackData) => {
  const cacheData = window.localStorage.getItem(cacheKey)
  if (!cacheData) return fallbackData
  try {
    return JSON.parse(cacheData)
  } catch {
    return fallbackData
  }
}

export const setLocalStorage = (cacheKey, data) => {
  window.localStorage.setItem(cacheKey, JSON.stringify(data))
}

export const openCccJumpUrl = (hrefInfo, router) => {
  const url = getCccJumpUrl(hrefInfo)
  if (url.indexOf('javascript:;') > -1) return
  if (!isInSpa(hrefInfo || {})) {
    window.open(url, '_blank')
    return
  }
  router.push(url)
}

export const getCccJumpUrl = hrefInfo => {
  const cccLink = new CccLink({})
  let url = cccLink.getLink({
    item: hrefInfo || {},
    cateLinks: {},
  })
  if (typeof window !== 'undefined') {
    url = cccLink.replacePwaUrl(url)
  }
  return url
}

export const formatUrlQuery = (queryObj = {}) =>
  Object.keys(queryObj).reduce(
    (sum, key) => `${sum}${queryObj[key] ? `&${key}=${queryObj[key]}` : ''}`,
    '',
  )

// 获取埋点数据
// type 1曝光 2 点击
export const getBuriedData = (buriedObject = [], type = 1) => {
  if (!(buriedObject && Array.isArray(buriedObject))) return
  return (
    buriedObject?.find(item => +item.buriedType === +type)?.defaultParams || {}
  )
}

// 获取埋点数据
export const getExtraData = (data = {}) => {
  if (!data) return
  const obj = { ...data, order_id: data.billno }
  delete obj.billno
  return obj
}

// 客户端接口异常上报
export const reportClientErrorLog = params => {
  if (typeof window === 'undefined') {
    console.error('Only used on the client side!')
    return
  }
  const { message, tag, ...extraInfo } = params
  SILogger.logError({
    page_name: 'page_me',
    params: extraInfo,
    message,
    tag: tag || '',
  })
}

// 上报主接口耗时
export const reportMainApiDuration = () => {
  return SIMetric.startMetricTime(
    {
      metric_name: 'api_duration_time',
      tags: {
        site: gbCommonInfo.SiteUID,
        tag: 'web_client_userCenter_mainApiDuration',
      },
    },
    {
      random: 0.5,
    },
  )
}

// 上报新续费弹框/旧续费弹框/免费礼弹框/回本弹框四种弹框弹出成功率
export const reportUserCenterDialogSuccess = params => {
  const { status, dialogValue } = params.tags
  const result = status === '1' ? 'success' : 'failed'
  const dialogName = params.dialogName
  delete params.dialogName
  SIMetric.metricCount({
    metric_name: 'pwa_user_dialog_success_total',
    tags: {
      status,
      dialog: dialogValue,
    },
    params,
    message: `${dialogName}: dialog opened ${result}`,
  })
}

const newPosKeys = [
  'SearchPerformance',
  'MostPopular',
  'NewPicSearch',
  'PicSearchUpgrade',
  'SearchTrendNew',
  'BackToSugg',
  'newsearch', // 搜索框样式强化
  // 'refreshpresearch', // 搜索结果页返回预搜页是否刷新联想词
  'searchwordstyle',
  'idpresearch',
  'idsearchdiscovery',
]
const posKeys = [
  'PicSearch',
  'PicSearchStrategy',
  'PicSearchExpandPop',
  'AllListQuickShip',
  'ListSearchSort',
]

export default [...posKeys, ...newPosKeys]

import { ApiCache } from 'public/src/pre_requests/modules/ApiCache/index.js'
import { getMessageLanguageApi, getMessageCCCDataApi } from './api'
import { LANGUAGE_KEYS } from 'public/src/pages/common/common-coupon/language.js'
import schttp from 'public/src/services/schttp'
import { isLogin } from 'public/src/pages/common/utils/index.js'

export const messageLanguageApiCache = new ApiCache({
  cacheTime: 5 * 60 * 1000,
  async request() {
    const [languageResult, couponLanguageResult] = await Promise.all([
      getMessageLanguageApi({
        pages: ['message'],
      }),
      schttp({
        url: '/system/configs/multi_language',
        method: 'POST',
        data: {
          languageKeys: LANGUAGE_KEYS,
        },
        useBffApi: true,
      }),
    ])

    return {
      ...languageResult?.info?.result,
      ...couponLanguageResult?.info?.result,
    }
  },
})

export const messageIndexApiCache = new ApiCache({
  request() {
    const { IS_RW } = gbCommonInfo

    return Promise.all([
      window._abt_server_provider.getUserAbtResult({
        newPosKeys: 'Message2024',
      }),
      !IS_RW && isLogin()
        ? getMessageCCCDataApi({
          cccPageType: 'insideMailPage',
        })
        : null,
    ])
  },
})

import schttp from 'public/src/services/schttp'

export const getMessageCouponListApi = (params) => {
  return schttp({
    method: 'GET',
    url: '/user/message/get_coupon_list',
    params: params,
    useBffApi: true
  })
}

export const bindCouponApi = (params) => {
  return schttp({
    method: 'POST',
    url: '/user/coupon/bind_coupon',
    data: params,
    useBffApi: true
  })
}

//获取未读消息
export const getMessageUnReadApi = (params) => {
  return schttp({
    method: 'GET',
    url: '/user/v3/msg_unread',
    params: params,
    useBffApi: true
  })
}

//设置已读消息
export const setMessageReadApi = (params) => {
  return schttp({
    method: 'POST',
    url: '/user/v2/message_set_read',
    params: params,
    useBffApi: true
  })
}

//获取多语言
export const getMessageLanguageApi = (params) => {
  return schttp({
    method: 'POST',
    url: '/system/configs/page_multi_language_mapping',
    data: params,
    useBffApi: true
  })
}

export const getMessageTipApi = () => {
  return schttp({
    method: 'GET',
    url: '/user/v2/message_get_tip',
    useBffApi: true
  })
}

//删除站内信消息
export const messageDeleteApi = (params) => {
  return schttp({
    method: 'POST',
    url: '/user/v2/message_delete',
    data: params,
    useBffApi: true
  })
}

//分页获取订单消息列表
export const getOrderListApi = (params) => {
  return schttp({
    method: 'GET',
    url: '/user/v2/message_order_list',
    params: params,
    useBffApi: true
  })
}

//分页获取新闻消息列表
export const getNewsListApi = (params) => {
  return schttp({
    method: 'GET',
    url: '/user/v2/message_news_list',
    params: params,
    useBffApi: true
  })
}

//分页获取催销消息列表
export const getPromoListApi = (params) => {
  return schttp({
    method: 'GET',
    url: '/user/v2/get_promo_message',
    params: params,
    useBffApi: true
  })
}

//分页获取activity消息列表
export const getActivityListApi = (params) => {
  return schttp({
    method: 'GET',
    url: '/user/v2/message_activity_list',
    params: params,
    useBffApi: true
  })
}

export const getCCCDataBFFApi = params => {
  return schttp({
    method: 'GET',
    url: '/ccc/common_component',
    params: params,
    useBffApi: true
  })
}

export const getMessageCCCDataApi = async params => {
  const res = await getCCCDataBFFApi(params)

  if (res?.code === '0') {
    const data = res.info?.content?.reduce(
      (acc, item) => {
        if (item.placeHolderKey === 'vertical_diversion') {
          acc.vcData.push(...item?.props?.items)
        } else if (item.placeHolderKey === 'horizontal_diversion') {
          acc.hzData.push({
            ...item?.props?.metaData,
            aodId: item?.aodId,
            materialName: item?.materialName,
          })
        }

        return acc
      },
      { hzData: [], vcData: [] },
    )

    return {
      hzData: data?.hzData.slice(0, 10),
      vcData: data?.vcData.length < 3 ? [] : data?.vcData.slice(0, 10),
      cateLinks: res?.info?.cateLinks,
    }
  }

  return null
}

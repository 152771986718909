const buyer = [
  {
    // path: `/*buyer/show`,
    path: `/:langPath?/buyer/show`,
    name: 'buyerShow',
    meta: {
      keepAlive: true,
      noNeedEmitPageUnload: true,
      notNeedCommonHeader: true
    },
    component: () => import(/* webpackChunkName: "show_container" */'../../buyer/show-container.vue')
  }
]

export { buyer }

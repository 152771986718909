import { analysisPlugin } from '@shein-aidc/utils-ccc'
import createApp from './app'
// import { mixin } from './client_mixin'
import { routes, clientFunc } from './router/client'

import cartTagTips from './util/cartTagTips/index.js'
// import _requestAnimationFrame from 'public/src/pages/common/renderFrameMixin/polyfill.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { businessLibInitializeForCSR } from './business-lib-initialize/csr'
import CallAppClass from 'public/src/pages/common/analysis/callApp.js'
import originUserMixin from './mixin/origin_user_mixin'
import infiniteScroll from '@sheinfe/vue-infinite-scroll'
import analysisDirective from 'public/src/pages/common/analysis/directive.js'
import { quickAdd } from '../components/product/quick_add_v2/index'
import wishGroupDrawer from '../components/wishGroupDrawer'
import { useVueErrorHandler } from 'public/src/pages/common/monitor/index.js'
import { useOriginUserRouteHook } from './hook/use-origin-user-route-hook.js'

const { contextForSSR } = gbCommonInfo

window._gb_cart_tag_tips_ = cartTagTips

const { app, router, store } = createApp(contextForSSR, {
  routes,
  // mixin,
  clientFunc
})

// from client_mixin.js
app.mixin(originUserMixin)
app.config.globalProperties.$instanceName = 'product'
app.config.globalProperties.$_WD = wishGroupDrawer // 新的分组弹框
app.config.globalProperties.$quickAdd = quickAdd
app.config.globalProperties.$evt = window._GB_LoginEventCenter_
app.config.globalProperties.$callApp = {
  value: CallAppClass,
  configurable: false,
  writable: false,
  enumerable: false
}
app.use(analysisPlugin)
app.use(analysisDirective)
app.use(infiniteScroll)
useVueErrorHandler(app)

businessLibInitializeForCSR({ router, app })
useOriginUserRouteHook(router)

router.beforeResolve((to, from, next) => {
  const matched = to.matched
  const prevMatched = from.matched
  let diffed = false
  const activated = matched.filter((c, i) => {
    return diffed || (diffed = (prevMatched[i] !== c) || to.name === 'config_activity' || to.name === 'campaigns')
  })
  const asyncDataHooks = activated.map(c => c.components.default.asyncData).filter(_ => _)
  if (!asyncDataHooks.length) {
    return next()
    // return _requestAnimationFrame(next)
  }
  // 执行asyncData hooks 传递直出的数据
  Promise.all(asyncDataHooks.map(hook => {
    markPoint({ eventName: 'pageAsyncData' })
    markPoint({ eventName: 'pageAsyncDataHook', measureFrom: 'routeChangeStart' })
    return hook({
      store,
      context: contextForSSR,
      to,
      route: to,
      from,
    })
  }))
    .then((res) => {
      if(res?.[0] == 'uninstall') {
        delete activated[0].components.default.asyncData
      }
      next()
      // _requestAnimationFrame(next)
    })
    .catch(next)
})

// 由于 _gb_app 必须在 router.onReady 中注册，而在注册之前可能被 EventBus 事件绑定使用，所以在未注册前将事件暂存
// 进队列，待注册后重新执行绑定
let gbApp
Object.defineProperty(window, '_gb_app_', {
  get () {
    return gbApp
  },
  set (v) {
    gbApp = v
  }
})

router.isReady().then(() => {
  // 暴露全局变量 mounted 之前window._gb_app_ 都为undefined
  window._gb_app_ = app.mount('#app')

  cartTagTips.initialize()
})

markPoint({ eventName: 'productAppJsLoaded', measureTo: 'pageAsyncData' })
markPoint({ eventName: 'productAppJsLoaded', measureFrom: 'resLoaderStart' })

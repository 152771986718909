/*!/**
* vuex-router-sync v6.0.0-rc.1
* (c) 2021 Evan You
* @license MIT
*/
function sync(store, router, options) {
  const moduleName = (options || {}).moduleName || 'route'
  store.registerModule(moduleName, {
    namespaced: true,
    state: cloneRoute(router.currentRoute.value),
    mutations: {
      ROUTE_CHANGED(_state, transition) {
        store.state[moduleName] = cloneRoute(transition.to, transition.from)
      }
    }
  })
  let isTimeTraveling = false
  let currentPath
  // sync router on store change
  const storeUnwatch = store.watch(
    state => state[moduleName],
    route => {
      const { fullPath } = route
      if (fullPath === currentPath) {
        return
      }
      if (currentPath != null) {
        isTimeTraveling = true
        router.push(route)
      }
      currentPath = fullPath
    },
    { flush: 'sync' }
  )
  // sync store on router navigation
  const afterEachUnHook = router.afterEach((to, from) => {
    if (isTimeTraveling) {
      isTimeTraveling = false
      return
    }
    currentPath = to.fullPath
    store.commit(moduleName + '/ROUTE_CHANGED', { to, from })
  })
  return function unsync() {
    // remove router hook
    afterEachUnHook()
    // remove store watch
    storeUnwatch()
    // unregister Module with store
    store.unregisterModule(moduleName)
  }
}

function cloneRoute(to, from) {
  const clone = {
    name: to.name,
    path: to.path,
    hash: to.hash,
    query: to.query,
    params: to.params,
    fullPath: to.fullPath,
    meta: to.meta
  }
  if (from) {
    clone.from = cloneRoute(from)
  }
  return Object.freeze(clone)
}

export { sync }

const needTransferPages = [
  'campaigns',
  'Prime'
]

export default function transferToMpa(to, from) {
  const toPage = to.name
  const toFullPath = to.fullPath
  const fromPage = from.name
  let needTransfer = false
  if (fromPage && (needTransferPages.includes(toPage) || needTransferPages.includes(fromPage))) {
    needTransfer = true
    const { host, langPath } = window.gbCommonInfo || {}
    const lang = toFullPath.includes(langPath) ? '' : langPath
    window.location.href = `${host}${lang}${toFullPath}`
  }
  return needTransfer
}

import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { preloadImg } from 'public/src/pages/ccc-campaigns/components/common/utils.js'
import { fetchCampaignsPageData } from 'public/src/pre_requests/modules/campaigns.js'
import { listenFetchSpaSsrPromise } from 'public/src/pre_requests/utlis/spaHydration.js'
const { langPath, FIRST_LEVEL_PATH_LIST = [] } = gbCommonInfo || {}

const urlPathList = FIRST_LEVEL_PATH_LIST?.length && FIRST_LEVEL_PATH_LIST.map(p => `${langPath}${p}`)

window.campaignsPrerenderSsr = true
window.campaignsPreloadPageMap = {}

let pendingding
let ready
if (/.*\/campaigns\/.*/.test(location.pathname)) {
  window.fetchCampaignsContentInfo = null
}

export const campaignsRouter = {
  path: '/:any?/campaigns/:identity?',
  name: 'campaigns',
  component: prefetchResource.importAsyncComponent({
    chunkName: 'ccc-campaigns',
    componentFactory: () =>
      import(/* webpackChunkName: "ccc-campaigns" */ '../../ccc-campaigns/App.vue')
        .then(r => ready || !pendingding ? Promise.resolve(r) : pendingding.then(() => { pendingding = null; return Promise.resolve(r) }))
  }),
  beforeEnter: (to, from, next) => {
    if (from.name && from.name != 'campaigns') {
      const isCurrentSpaSsr = listenFetchSpaSsrPromise({ name: 'campaigns' })
      if (to?.params?.identity === 'shein_new' && window.fetchCampaignsContentInfo !== null) {
        window.forceUpdateHeaderFixed?.({ forceFixed: false, routeName: 'campaigns' })
        window.fetchCampaignsContentInfo = window.fetchCampaignsSheinNewInfo
      }
      if (window.fetchCampaignsContentInfo !== null && gbCommonInfo.CAMPAIGNS_PRERENDER_SWITCH === 'on') {
        ready = !!window.fetchCampaignsContentInfo
        pendingding = (window.fetchCampaignsContentInfo || fetchCampaignsPageData({
          ...(to.query || {}),
          identity: to?.params?.identity,
          tabIndex: to?.params?.tabIndex || 0,
          prefetch: 1,
          gbSuiSsrData: window.gbSuiSsrData?.join?.() || ''
        })).then(res => {
          return new Promise(resolve => {
            let { contextForSSR, ssrHTML, appendedSelector, ssrSuiCSS, preloadImgs } = res || {}
            if (contextForSSR && isCurrentSpaSsr()) {
              if (ssrHTML) {
                if (ssrSuiCSS && appendedSelector) {
                  document.querySelector('#ssrSuiCSS').textContent += ssrSuiCSS
                  window.gbSuiSsrData = [... new Set((window.gbSuiSsrData || []).concat(appendedSelector))]
                }
                if (!(to?.params?.identity === 'shein_new' && to?.query?.from_tabbar_new)) {
                  window._gb_app_.$store.state.rootStatus.exchangingSpaSceneInApp = true
                }
                const ssrHTMLFragment = document.createRange().createContextualFragment(ssrHTML)
                const ssrHTMLFragmentEl = ssrHTMLFragment.querySelector('#ccc-campaigns')
                document.querySelector('#prerender-in-app').appendChild(ssrHTMLFragmentEl)
                document.querySelector('#prerender-css-campaigns').innerHTML = contextForSSR.styles || ''
                document.querySelector('#prerender-in-app #ccc-campaigns').setAttribute('data-server-rendered', true)
                let imgs
                if (preloadImgs.length) imgs = preloadImg(preloadImgs)
                requestAnimationFrame(() => { // 上屏前
                  window.campaignsSsrData = contextForSSR
                  window.scrollTo(0, 0)  
                  imgs
                    ? imgs.then(() => requestAnimationFrame(() => resolve()))
                    : requestAnimationFrame(() => resolve())
                })
              } else {
                window.campaignsSsrData = contextForSSR
                resolve()
              }
            } else {
              resolve()
            }
          })
        }).then(() => ready && next()).catch(() => location.reload())
        window.fetchCampaignsContentInfo = null
        window.campaignsPrerenderSsr = false
        window.campaignsPreloadPageMap = {}
        ready || next()
      } else {
        window.campaignsPrerenderSsr = false
        next()
      }
    } else {
      window.campaignsPrerenderSsr = false
      next()
    }
  },
  meta: {
    keepAlive: true,
    isCssInVue: true,
    pageType: 'campaigns',
    monitorTag: 'campaigns'
  },
  alias: [...urlPathList]
}
  

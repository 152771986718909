<template>
  <div 
    v-expose="{
      id: '2-9-23',
      data: { search_box_form: 2 }
    }"
    class="store-header__center-search"
  >
    <div 
      class="store-header_search-input"
      @click="handleClickSearchInput"
    >
      <StoreWordsSwiper
        v-if="storeDefaultWords.length > 0"
        ref="storeWordsSwiperRef"
        :show-new-style="true"
        :storeDefaultWords="storeDefaultWords"
      />
      <div
        v-else
        class="search-placeholder"
      >
        {{ language.SHEIN_KEY_PWA_24178 }}
      </div>
    </div>
    <div 
      class="store-header_search-btn" 
      @click="handleClickSearchIcon"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.91914 2C5.0978 2 2 5.0978 2 8.91914C2 12.7405 5.0978 15.8383 8.91914 15.8383C10.5476 15.8383 12.0447 15.2757 13.2266 14.3343L16.4263 17.534L17.5577 16.4026L14.3552 13.2002C15.284 12.0224 15.8383 10.5355 15.8383 8.91914C15.8383 5.0978 12.7405 2 8.91914 2ZM3.6 8.91914C3.6 5.98146 5.98146 3.6 8.91914 3.6C11.8568 3.6 14.2383 5.98146 14.2383 8.91914C14.2383 11.8568 11.8568 14.2383 8.91914 14.2383C5.98146 14.2383 3.6 11.8568 3.6 8.91914Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StoreHeaderCenter', // 店铺中间
})
</script>

<script setup>
import { vExpose } from 'public/src/pages/common/analysis/directive'
import { ref } from 'vue'
import { markPoint } from 'public/src/services/mark/index.js'
import { spaJump } from 'public/src/pages/common/utils/index.js'
import { useStorePageData,  useInjectStoreHeader } from '../../hooks/useStoreHeader.js'
import { getQueryString, stringifyQueryString, setSessionStorage } from '@shein/common-function'
import { searchWordsGlobalAnalysis } from 'public/src/pages/common/biz_helper/gb_sw_common'
import StoreWordsSwiper from './StoreWordsSwiper.vue'
import { setUserActionTrackerDataOnce } from 'public/src/pages/common/userActionTracker/core/index.js'

const { storeDefaultWords } = useInjectStoreHeader()
const { storeCode, language, router, route, langPath, assignRootState } = useStorePageData()

const storeWordsSwiperRef =  ref(null)
const handleClickSearchInput = ()=>{
  let hot_index = 0
  let hot_word = ''
  // 有热搜次
  if(storeDefaultWords.value.length > 0 && storeWordsSwiperRef.value){
    hot_index = storeWordsSwiperRef.value.activeIndex
    hot_word = storeDefaultWords.value[hot_index]?.word || ''
  }
  // 上报
  daEventCenter.triggerNotice({
    daId: '2-9-22',
    extraData: {
      name: 'click_store_search',
      search_box_form: 2,
      search_content: hot_word,
    }
  })
  goPresearch({
    hot_word, 
    hot_index
  })
}

const handleClickSearchIcon = ()=>{
  let hot_index = 0
  let hot_word = ''
  // 有热搜次
  if(storeDefaultWords.value.length > 0 && storeWordsSwiperRef.value){
    hot_index = storeWordsSwiperRef.value.activeIndex
    hot_word = storeDefaultWords.value[hot_index]?.word || ''
  }
  const fixedKeyword = hot_word.replace(/%/g, '%25')
  let url = `${langPath}/pdsearch/${encodeURIComponent(fixedKeyword)}`
  // 因为搜索是预请求的，所以在这里提前记录行为
  const actionId = setUserActionTrackerDataOnce(
    'search_cycle', 
    { 
      activity_name: 'click_icon_search', 
      activity_param: { search_content: hot_word }, 
      timestamp: Date.now(),
      page_name: 'page_store',
    }, {
      to: {
        name: gbCommonInfo.newSearchContainer ? 'search-product-list' : 'product-list-v2',
        path: url, // 跳转下个页面的path
        query: {},
        params: {
          isPreFlag: 1, // 预处理标识
        },
      },
      from: router.currentRoute.value
    }
  )
  // 上报
  daEventCenter.triggerNotice({
    daId: '2-9-22',
    extraData: {
      search_box_form: 2,
      search_content: hot_word,
      name: 'click_icon_search',
      actionId,
    }
  })
  searchWordsGlobalAnalysis.set({
    result_type: 3,
    word_type: '',
    result_word: fixedKeyword,
    d: 0,
    parent_page: 'page_store',
    entrancetype: SaPageInfo?.page_param?.entrancetype || '-',
  })

  if(storeDefaultWords.value.length > 0 &&  storeWordsSwiperRef.value){
    return goPdSearchPage({
      url, 
      hot_index,
      hot_word,
    })
  }
  goPresearch({
    hot_word, 
    hot_index
  })
 
}

// 进入预搜页
const goPresearch = (params)=>{
  markPoint('toNextPageClick', 'public')
  const { hot_word, hot_index } = params || {}
  const { brand_id, } = route?.query || {}
  const query = {
    store_code: storeCode.value,
    pageType: 'store',
    pagefrom: window.SaPageInfo?.page_name,
    search_button: 1, // todo 123 abtShowSearchButton
  }

  if(brand_id){
    query.brand_id = brand_id
  }

  gbCommonInfo.keywords = ''
  assignRootState({
    currentDefaultKeyword: hot_word || '',
    mixedKeyword: '',
    searchSelectKeyWord: '',
  })
  // 标识进入新页面
  route.meta.isNewPage = true
  if(hot_word) {
    query.hot_word = hot_word
    setSessionStorage({ 
      key: `STORE_SEARCH_${storeCode.value}`,
      value: hot_index
    })
  }

  router.push({
    path: `${langPath}/presearch`,
    query,
  })
}

const goPdSearchPage = (params)=>{
  const { url, hot_index, hot_word } = params || {}
  const query = {
    store_code: storeCode.value,
    dont_use_suggest_word: 1,
    search_type: 'store', // 固定词
    src_module: 'store_search',
    ici: searchWordsGlobalAnalysis.getPageFrom()
  }
  const brand_id = getQueryString({ key: 'brand_id' })
  if(brand_id){
    query.brand_id = brand_id
  }
  query.src_identifier =  `st=3\`sc=${encodeURIComponent(hot_word)}\`sr=0\`ps=${hot_index + 1}`
  const src_tab_page_id = getQueryString({ key: 'src_tab_page_id' })
  if(src_tab_page_id){
    query.src_tab_page_id = src_tab_page_id
  }
  const queryString  = stringifyQueryString({ queryObj: query })
  const urlWithQs = (url + '?' + queryString).trim().replace('@', '-')
  spaJump({
    routerVm: router,
    type: 'push',
    url: urlWithQs,
  })
}
</script>
<style lang="less" scoped>
.store-header__center-search{
  flex-grow: 1;
  width: 1px;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  overflow: hidden;
  z-index: 1;
  height: 0.90667rem;
  border-radius: 0.48rem;
  border: 0.026667rem solid #222;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}
.store-header_search-input{
  flex-grow: 1;
  width: 1px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 12px;
}
.search-placeholder{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: rgb(102, 102, 102);
}
.store-header_search-btn{
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #222;
  width: 1.17333rem;
  height: 0.746667rem;
  border-radius: 0.373333rem;
  margin-right: 2px;
}

</style>

import { prefetchSdk } from '@customerService/robotDnsLink.js'
import { TMGTriggerForImToken } from 'public/src/pre_requests/modules/robot.js'

import { parseQueryString, extractQueryString } from '@shein/common-function'

// 获取url中的query参数
export const getQueryData = () => {
  return parseQueryString(extractQueryString(location.href))
}

const { langPath } = gbCommonInfo

const robotRoutes = [
  {
    path: `${langPath}/robot`,
    name: 'robot',
    meta: {
      pageType: 'robot',
      notNeedCommonHeader: true,
      keepAlive: true,
      isCssInVue: true
    },
    beforeEnter: async (to, from, next) => {
      // 提前预建立sdk请求连接
      prefetchSdk()
      // 提前预请求获取imToken/机器人多语言/abt/机器人初始化数据 & 且首次进入非单页,单页请求进入会请求多次
      TMGTriggerForImToken(to)

      next()
    },
    component: async () => {
      // 只是单纯为了监控sdk加载耗时
      window.initBotStartTime = new Date().getTime()
      // 发现一个bug component加载速度比vue-router的跳转增加参数要快，导致页面跳转后参数丢失，所以这里改成直接跳转到bot页面
      return import(/* webpackChunkName: "robotNew" */ '../../common/robotNew/bot/BotContainer')
    }
  },
  {
    path: `${langPath}/user/chats`,
    name: 'chats',
    meta: {
      pageType: 'chats',
      notNeedCommonHeader: true,
      keepAlive: true,
      isCssInVue: true
    },
    component: () =>
      import(/* webpackChunkName: "robotNew" */ '../../common/chats/App')
  },
  {
    path: `${langPath}/robot/business`,
    name: 'robotBusiness',
    meta: {
      pageType: 'business',
      notNeedCommonHeader: true,
      keepAlive: true,
      isCssInVue: true
    },
    component: () =>
      import(/* webpackChunkName: "robotBusiness" */ '../../common/robotBusiness/businessContainer')
  },
  {
    path: `${langPath}/contact-us.html`,
    name: 'ContactUs',
    component: () => import('../../user/child_pages/supportCommon/support_index.vue'),
    meta: {
      isCssInVue: true,
    },
  },
]

export {
  robotRoutes
}

import schttp from 'public/src/services/schttp'
import { ApiCache } from 'public/src/pre_requests/modules/ApiCache'

const formatQualityProductsParams = (params) => {
  const { limit = 20, page, goods_id, dp_pool_id, goods_sn, cat_id } = params || {}
  const formatLimit = Number(limit) || 20
  const formatPage = Number(page) || 1

  return {
    limit: formatLimit,
    page: formatPage,
    goodsId: goods_id || '',
    dpPoolId: dp_pool_id || '',
    goodsSn: goods_sn || '',
    catId: cat_id || '',
  }
}

/**
 * 获取页面公共数据
 */
export const getPageCommonData = (params, loadingConfig = {}) => {
  return schttp({
    method: 'GET',
    url: '/api/choices/pageCommonData',
    params: params,
    timeout: 10000,
    schttp: {
      loadingConfig,
    }
  })
}


const commonPageDataCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: async (params) => {
    return await getPageCommonData(params)
  },
  notCacheKeys: [],
})


export const getPageCommonDataCache = (params) => {
  return commonPageDataCache.request(params)
}

export const qualityProductsParams4Schttp = {
  method: 'POST',
  url: '/category-api/quality-selling/quality-products',
  timeout: 10000,
  useBffApi: true,
}

export const getPageData = async (params, loadingConfig = {}) => {
  const formatParams = formatQualityProductsParams(params)
  return schttp({
    ...qualityProductsParams4Schttp,
    params: formatParams,
    schttp: {
      loadingConfig,
    }
  })
}

const pageDataCache = new ApiCache({
  cacheTime: .5 * 60 * 1000, // 缓存时间 0.5 分钟
  request: async (params) => {
    return await getPageData(params)
  },
  notCacheKeys: [],
})

export const getPageDataCache = (params) => {
  return pageDataCache.request(params)
}

export const filterParams4Schttp = {
  method: 'GET',
  url: '/api/choices/filtersData',
  timeout: 10000,
}

export const getFiltersData = async (params) => {
  return schttp({
    ...filterParams4Schttp,
    params,
  })
}

const filtersDataCache = new ApiCache({
  cacheTime: .5 * 60 * 1000, // 缓存时间 0.5 分钟
  request: async (params) => {
    return await getFiltersData(params)
  },
  notCacheKeys: [],
})

export const getFiltersDataCache = (params) => {
  return filtersDataCache.request(params)
}

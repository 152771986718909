export const freeTrialLanguage = [
  'SHEIN_KEY_PWA_16268', // free trial center
  'SHEIN_KEY_PWA_16245', // Winner Announcement
  'SHEIN_KEY_PWA_16246', // In Progress
  'SHEIN_KEY_PWA_16247', // Next Notice
  'SHEIN_KEY_PWA_15056', // Ended
  'SHEIN_KEY_PWA_15207', // Trial Report
  'SHEIN_KEY_PWA_15040', // View All
  'SHEIN_KEY_PWA_15689', // time left
  'SHEIN_KEY_PWA_16212', // free trial
  'SHEIN_KEY_PWA_16133', // More
  'SHEIN_KEY_PWA_14980', // Category
  'SHEIN_KEY_PWA_15623', // All
  'SHEIN_KEY_PWA_15042', // Done
  'SHEIN_KEY_PWA_25346', // Clear
  'SHEIN_KEY_PWA_15884', // Next Step
  'SHEIN_KEY_PWA_16248', // Chance(s)
  'SHEIN_KEY_PWA_16249', // Applied
  'SHEIN_KEY_PWA_16234', // Featured
  'SHEIN_KEY_PWA_15167', // Buy Now
  'SHEIN_KEY_PWA_15332', // Rating
  'SHEIN_KEY_PWA_15694', // Overall Fit:
  'SHEIN_KEY_PWA_15001', // True to Fit
  'SHEIN_KEY_PWA_15003', // Large
  'SHEIN_KEY_PWA_15002', // Small
  'SHEIN_KEY_PWA_16325', // Once your review is approved, you\'ll get 20 SHEIN points. If your review gets rated as \"High Quality\", you\'ll get another 100 points and increase your chances for another Free Trial!
  'SHEIN_KEY_PWA_16224', // Remaining opportunities this week:
  'SHEIN_KEY_PWA_16226', // My Application
  'SHEIN_KEY_PWA_16227', // My report
  'SHEIN_KEY_PWA_16225', // My Free Trial
  'SHEIN_KEY_PWA_15248', // Expired
  'SHEIN_KEY_PWA_15220', // pending
  'SHEIN_KEY_PWA_15219', // passed
  'SHEIN_KEY_PWA_15311', // failed
  'SHEIN_KEY_PWA_16228', // Post Report
  'SHEIN_KEY_PWA_15740', // Order Detail
  'SHEIN_KEY_PWA_16235', // Rewrite
  'SHEIN_KEY_PWA_15233', // SKU: 
  'SHEIN_KEY_PWA_15819', // It is empty here :-(
  'SHEIN_KEY_PWA_15146', // OK
  'SHEIN_KEY_PWA_27442', // other, pLease contact us
  'SHEIN_KEY_PWA_27441', // Commentary is vulgar, insulting
  'SHEIN_KEY_PWA_27440', // Comment content is not related to the product
  'SHEIN_KEY_PWA_27439', // Comment content is not true
  'SHEIN_KEY_PWA_27438', // Photo is too revealing
  'SHEIN_KEY_PWA_27437', // Unflattering photo
  'SHEIN_KEY_PWA_27436', // The picture does not match the trial product
  'SHEIN_KEY_PWA_27435', // The picture is not clear
  'SHEIN_KEY_PWA_27434', // Use of another's photo
  'SHEIN_KEY_PWA_16220', // Quality, shippment & etc.No less than 50 words. An excellent trial report will increase your chances on winning the next drawing.
  'SHEIN_KEY_PWA_16233', // Minimum of 50 words
  'SHEIN_KEY_PWA_16219', // Upload Photos
  'SHEIN_KEY_PWA_16230', // The report must contain at least one overall photo of the item and at least one detailed photo in one of the following formats: jpg, png, and gif formats.
  'SHEIN_KEY_PWA_16231', // The report must include at least 1 overall item photo and at least 1 detailed photo
  'SHEIN_KEY_PWA_15061', // Please Choose Size
  'SHEIN_KEY_PWA_14999', // Overall Fit
  'SHEIN_KEY_PWA_15179', // Submit
  'SHEIN_KEY_PWA_16304', // Please enter your content.
  'SHEIN_KEY_PWA_16223', // Please Select
  'SHEIN_KEY_PWA_18348', // Range:
  'SHEIN_KEY_PWA_37201', // Nothing else
  'SHEIN_KEY_PWA_37202', // Please select the size that fits you best
  'SHEIN_KEY_PWA_37203', // All free
  'SHEIN_KEY_PWA_37204', // full package
  'SHEIN_KEY_PWA_37205', // priority experience
  'SHEIN_KEY_PWA_37206', // Please share your trial experience.
  'SHEIN_KEY_PWA_15701', // Size
  'SHEIN_KEY_PWA_15253', // No
  'SHEIN_KEY_PWA_15252', // Yes
  'SHEIN_KEY_PWA_15888', // Click OK to check your return label information
  'SHEIN_KEY_PWA_18264', // Submitted successfully
  'SHEIN_KEY_PWA_18265', // Do you want to check your trial report in the Free Trial Center
  'SHEIN_KEY_PWA_17298', // Your comment, photo, and size information will be displayed anonymously on the product comment page and trial report page.
  'SHEIN_KEY_PWA_18092', // The trial report cannot be filled in after more than 90 days of receipt.
]

import saModule from './sa'

if (typeof window !== 'undefined' && !gbCommonInfo.hasFirstLoadUserPage) {
  // 页面进入事件
  appEventCenter.on('pageOnload', ({ noSaPv } = {}) => {
    if (noSaPv) return
    saModule.handlePageOnload()
  })
  // 这个页面 在首页到分类页也执行了。  是否都需要执行？  a21手机上 耗时23ms
  appEventCenter.on('pageUnload', () => {
    saModule.handlePageUnload()
  })
}

<template>
  <div class="app-header">
    <div
      class="app-header__wrap"
      :class="{
        'app-header__wrap-hidden': !isVisibleHeader,
        'app-header__show-input': isShowInput && (showListSearchLayFlat || showListSearchSuspension)
      }"
      :style="commonHeaderColorVariable"
    >
      <b-common-header
        ref="commonHeaderRef"
        :headerStatus="headerStatus"
        :headerOptions="headerOptions"
        class="j-common-header-dom"
      >
        <!-- left slot -->
        <template
          #left
        >
          <div
            v-if="showBack"
            class="app-header__icon-left"
          >
            <BackIcon
              :isNeedWindowClose="isNeedWindowClose"
              @goBack="handleGoBack"
            />
          </div>
          <span
            v-if="showCategory"
            :class="showCategory ? 'app-header__icon-left' : ''"
          >
            <NavIcon @click="showSideCate" />
          </span>
          <ClientOnly>
            <BSlideCategory
              v-if="showSide"
              ref="sideCateRef"
            />
          </ClientOnly>
          <MessageIcon
            v-show="showMessage"
            ref="messageIconRef"
            class="app-header__icon-left"
            :messageViewMode="messageViewMode"
            @clickMessage="handleClickMessageIcon"
          />
        </template>

        <!-- center slot -->
        <template
          v-if="!isStorePage"
          #center
        >
          <div
            :style="{
              position: 'absolute',
              'pointer-events': !showLogo ? 'none': undefined,
              opacity: !showLogo ? 0 : 1,
              ...isSearchBoxNeedExpandWidth ? {} : { transition: !showLogo ? 'none' : 'opacity ease-in 0.2s', },
            }"
          >
            <BHeaderLogo
              :cccSkinHeaderLogo="cccSkinHeaderLogo"
            />
          </div>
          <ClientOnly>
            <LegacyHeader
              v-show="!showProductDetailTab"
              ref="legacyHeaderRef"
              :iconColor="iconColor"
              :showSearchStoreNewStyle="showSearchStoreNewStyle"
              :isStorePageOrSearchPage="isStorePageOrSearchPage"
              :isStoreSearchPage="isStoreSearchPage"
              :isTopTrendResultPage="isTopTrendResultPage"
              :isCategoryPage="isCategoryPage"
              :showProductDetailTab="showProductDetailTab"
              :searchBarCmcConfig="searchBarCmcConfig"
              @isShowInput="handleIsShowInput"
              @inputWidthExpand="handleInputWidthExpand"
              @inputWidthShrink="handleInputWidthShrink"
              @searchStickyInfoChange="handleSearchStickyStatus"
              @listSearchLayFlat="handleListSearchLayFlat"
              @listSearchSuspension="handleListSearchSuspension"
            />
            <ProductDetailTab
              v-if="showProductDetailTab"
              v-show="isShowInput"
            />
          </ClientOnly>
        </template>

        <!-- right slot -->
        <template #right>
          <template v-if="!isStorePage && !showSearchStoreNewStyle">
            <SearchIcon
              v-show="isSearchBoxNeedExpandWidth ? !isInputWidthExpand: !isShowInput"
              ref="preSearchEntry"
              class="app-header__icon-right"
              :abtest="searchAbtResult"
              :route-query="searchIconQuery"
              :extends-report="searchIconExtendsReport"
              :goPresearchCallback="goPresearch"
              :handleExpose="true"
            />
            <SaveIcon
              v-if="showSave"
              ref="wishListEntry"
              :style="saveIconStyle"
              class="app-header__icon-right"
            />
            <!-- 分享 icon start -->
            <a
              v-if="showShare"
              tabindex="0"
              role="link"
              href="javascript:;"
              class="app-header__icon-right new-header-share-icon"
              @click="handleShare"
            >
              <i
                class="suiiconfont sui_icon_share_24px"
                :style="{ color: iconColor }"
              ></i>
            </a>
            <!-- 分享 icon end -->
            <BHeaderCart
              v-if="showCart"
              :style="cartIconStyle"
              class="app-header__icon-right"
              loc="topsite"
              :disabledLure="hasFloatCartInDetailPage"
              @before-click="onBeforeClickCart"
            />
            <Wish
              v-if="showWish"
              class="app-header__icon-right"
            />
          </template>
          <template v-if="isStorePage">
            <StoreHeaderSearch />
            <StoreHeaderRight />
          </template>
        </template>
      </b-common-header>
    </div>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
export default defineComponent({
  // for vue debug
  name: 'AppHeader',
  components: {
    BSlideCategory: defineAsyncComponent(() => import( /* webpackChunkName: "sidecategory" */ '../../category/sidecontainer.vue')),
    ProductDetailTab: defineAsyncComponent(() => import( /* webpackChunkName: "ProductDetailTab" */ 'public/src/pages/components/app_header/components/LegacyHeader/ProductDetailTab.vue')),
    LegacyHeader: defineAsyncComponent(() => import( /* webpackChunkName: "LegacyHeader" */ './components/LegacyHeader/LegacyHeader.vue')),
  }
})
</script>


<script name="AppHeader" setup>
import { defineProps, computed, ref, onMounted, nextTick, onUnmounted, defineEmits, watch } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
// import bs components start
// import BSlideCategory from './components/BsCategory.vue'
import { BCommonHeader } from '@shein-aidc/bs-common-header-mobile'
import { useProxyHosts } from '@shein-aidc/bs-sdk-libs-manager'
import { BBackIcon as BackIcon } from '@shein-aidc/bs-back-icon-mobile'
import { BMessageBar as MessageIcon } from '@shein-aidc/bs-message-bar-mobile'
// import { BSlideCategory } from '@shein-aidc/bs-category-mobile'
// import { BFooterSheinInfo } from '@shein-aidc/bs-footer-shein-info-mobile'
import { BHeaderCart } from '@shein-aidc/bs-header-cart-mobile'
import { BPreSearchEntry as SearchIcon } from '@shein-aidc/bs-pre-search-entry-mobile'
import { BWishListEntry as SaveIcon } from '@shein-aidc/bs-wish-list-entry-mobile'
import StoreHeaderSearch from './components/store-page/StoreHeaderSearch.vue'
import StoreHeaderRight from './components/store-page/StoreHeaderRight.vue'
// import LegacyHeader from './components/LegacyHeader/LegacyHeader.vue'

import Wish from './components/Wish.vue'
import { BHeaderLogo } from '@shein-aidc/bs-header-logo-mobile'
import NavIcon from './components/NavIcon.vue'
// import bs components end

// import hooks start
import useBack from './hooks/useBack.js'
import useMessage from './hooks/useMessage.js'
// import useCategory from './hooks/useCategory.js'
import useSearchIcon from './hooks/useSearchIcon.js'
import useSave from './hooks/useSave.js'
import useShare from './hooks/useShare.js'
import useCart from './hooks/useCart.js'
import useWish from './hooks/useWish.js'
import useIs from './hooks/is.js'
import useCommon from './hooks/common.js'
import { useProvideStoreHeader } from './hooks/useStoreHeader.js'
import { useRouter } from './hooks/router.js'
import { useGlobalMapState } from 'public/src/pages/components/app_header/hooks/store.js'
import useLogo from './hooks/useLogo.js'
import useSearchInput from './hooks/useSearchInput.js'
// import hooks end

// use hooks start
const { router, route } = useRouter()
const emit = defineEmits(['changeHomeImmersiveInfo'])
const routeRef = ref(route || {})
const { goodsDetailInfo, hasNewTopBanner } = useGlobalMapState(['goodsDetailInfo', 'hasNewTopBanner'])

const {
  isStorePage,
  isStoreSearchPage,
  isStorePageOrSearchPage,
  isGoodsDetailPage,
  isTopTrendResultPage,
  isCategoryPage
} = useIs({ route: routeRef })
const {
  pageScene,
  showSearchStoreNewStyle,
} = useCommon({ route: routeRef, isStorePageOrSearchPage })
const searchStickyInfo = ref({})
const handleSearchStickyStatus = (stickyInfo) => {
  searchStickyInfo.value = stickyInfo
}

const showListSearchSuspension = ref(false)
const handleListSearchSuspension = (flag) => {
  showListSearchSuspension.value = flag
}

const { isStoreTopTabSticky } = useProvideStoreHeader({ route: routeRef }) // 店铺场景
// const { bSlideCategory, showCategory, windowOneTrust, unReadChatNum,  isSupportOneTrust,  handleOntrustClick, goPage, preload, setMonitor } = useCategory({ route: routeRef, isStorePage, isStoreTopTabSticky })
const { showBack, handleGoBack, isNeedWindowClose } = useBack({ route: routeRef })
const { showMessage, messageIconRef, handleClickMessageIcon } = useMessage({ route: routeRef })
const { isShowInput, handleIsShowInput, preSearchEntry, goPresearch, searchAbtResult, searchIconQuery, searchIconExtendsReport } = useSearchIcon({ pageScene, route: routeRef, router })
const { showSave, saveIconStyle, wishListEntry } = useSave({ pageScene, searchStickyInfo, isShowInput, route: routeRef })
const { showShare, handleShare } = useShare({ route: routeRef })
const { showCart, handleListSearchLayFlat, showListSearchLayFlat, cartIconStyle,  onBeforeClickCart, hasFloatCartInDetailPage } = useCart({ route: routeRef, searchStickyInfo, isShowInput })
const { showWish } = useWish({ route: routeRef })
const { isSearchBoxNeedExpandWidth, isInputWidthExpand, handleInputWidthExpand, handleInputWidthShrink, searchBarCmcConfig } = useSearchInput({ route: routeRef, router, isShowInput })
const { showLogo } = useLogo({ route: routeRef, isSearchBoxNeedExpandWidth, isShowInput, isInputWidthExpand })
// use hooks end

// props start
const props = defineProps({
  showAppHeader: {
    type: Boolean,
    default: true,
  },
  commonHeaderBg: {
    type: Object,
    default: () => {},
  },
  commonHeight: {
    type: Object,
    default: () => {},
  },
  showImmersiveBanner: {
    type: Boolean,
    default: false,
  },
  isAddCommonHeaderClassName: {
    type: Boolean,
    default: false,
  },
  // 站内信 icon 专有
  messageViewMode: {
    type: String,
    default: '',
  },
  cccSkinHeaderLogo: {
    type: String,
    default: '',
  },
})
// props end

// BCommonHeader组件 start (!!! commonHeader组件相关的逻辑才写在这里面，子组件逻辑尽量写到对应hooks里 !!!)
const commonHeaderRef = ref(null)
const iconColor = computed(() => {
  // 如果有优先定义
  if (props.commonHeaderBg.useDefineTextColor) {
    return props.commonHeaderBg?.topTextColor || ''
  }
  return props.commonHeaderBg?.showBlock ? (
    props.stickyTop && props.showImmersiveBanner && pageScene.value === 'homeIndex' ? '#fff' : props.commonHeaderBg?.topTextColor || '#000'
  ) : '#000'
})
const commonHeaderColorVariable = computed(() => {
  return {
    '--shein-common-header-icon-color': iconColor.value, // 自定义 icon 的颜色
    '--shein-common-header-badge-border-color': iconColor.value ? '#fff' : '', // 自定义了 icon 的颜色时，需要给小红点设置白边
  }
})

const showCategory = computed(() => {

  // v.趋势搜索结果页屏蔽该功能
  if (isTopTrendResultPage.value) {
    return false
  }

  // 店铺页并且店铺tab吸顶时不显示
  if (isStorePage.value && isStoreTopTabSticky.value) {
    return false
  }
  const excludePages = ['page_category']
  return !excludePages.includes(routeRef.value?.name)
})

const showBorderBottom = computed(() => {
  const routeName = routeRef.value?.name
  return !props.commonHeaderBg.showBlock && !['config_index', 'page_category', 'campaigns'].includes(routeName)
})

const isVisibleHeader = ref(true)
const onVisibleHeaderChange = flag => isVisibleHeader.value = flag


// 只给headerStyle用
const backgroundColor = computed(() => {
  const { immersiveFlag, backgroundColor, bgImgSrc, showBlock } = props.commonHeaderBg

  if (!showBlock) return '#FFFFFF'
  if (immersiveFlag || bgImgSrc) return 'transparent'
  if (!bgImgSrc && backgroundColor) {
    return backgroundColor
  } else {
    return '#FFFFFF'
  }
})

const headerStyle = computed(() => {
  let style = {
    position: 'relative',
    height: '1.17rem',
    width: '10rem',
    padding: '0 0.32rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: '11',
    transform: 'translate3d(0, 0, 11px)',
    background: '',
    borderBottom: showBorderBottom.value
      ? '1px solid #e5e5e5'
      : 'none',
    overflow: ''
  }
  style.background = backgroundColor.value
  if(showWish.value) {
    style.overflow = 'revert'
  }

  return style
})

const headerBgStyle = computed(() => {
  let basicStyle = {
    position: 'absolute',
    height: (props.commonHeight?.topBgHeight || 0) + 'rem',
    zIndex: 1,
    top: 0,
    width: '10rem',
    'background-repeat': 'no-repeat',
    backgroundSize: '100% auto',
    backgroundPosition: '0 0',
    backgroundColor: props.showImmersiveBanner
      ? 'transparent'
      : props.commonHeaderBg.backgroundColor,
    background: '',
    backgroundImage: '',
    pointerEvents: 'none'
  }

  if (props.commonHeaderBg.immersiveFlag) {
    basicStyle.background = 'transparent'
  } else if(props.commonHeaderBg.bgImgSrc){
    basicStyle.backgroundImage = `url(${props.commonHeaderBg.bgImgSrc})`
  }
  return basicStyle
})

const isHeaderBackgroundImage = computed(() => {
  return props.commonHeaderBg?.immersiveFlag || props.commonHeaderBg?.bgImgSrc
})

let headerStatusCache = {
  showBg: '',
  bgStyle: {},
  wrapClassName: '',
  wrapStyle: {},
  leftSlotStyle: {}
}
const headerStatus = computed(() => {
  const result = {
    showBg: props.commonHeaderBg?.showBlock && isHeaderBackgroundImage.value,
    bgStyle: headerBgStyle.value,
    wrapClassName: `common-logo-header ${props.isAddCommonHeaderClassName ? 'j-common-logo-header' : ''}`,
    wrapStyle: headerStyle.value,
  }
  if (showSearchStoreNewStyle.value || isStoreSearchPage.value) {
    result.leftSlotStyle = {
      'position': 'relative',
      'z-index': 1,
    }
  }
  if (isStorePage.value) {
    result.rightSlotStyle = {
      'flex-grow': 1,
      width: '1px',
    }
  }

  // 对比是否有变化
  if (JSON.stringify(headerStatusCache) === JSON.stringify(result)) {
    return headerStatusCache
  }
  headerStatusCache = result
  return result
})

const closeScrollEvent = computed(() => {
  return routeRef.value?.meta?.notNeedCommonHeader === true
})

const sideCateRef = ref(null)
const showSide = ref(false)
function showSideCate(){
  showSide.value = true
  if(sideCateRef.value){
    sideCateRef.value.showDrawer()
  }
}

const headerOptions = {
  closeScrollEvent
}

const setHeaderByRouteAfterEach = (to, from) => {
  // 触发 switchRouter 代理事件
  useProxyHosts().CommonHeader?.trigger('switchRouter', {
    to, from
  })
  setTimeout(() => {
    resetStickyOffset() // 切换路由，矫正吸顶
  }, 100)
}

const resetStickyOffset = () => {
  commonHeaderRef.value?.commonHeaderContext.headerFixedInstance?.invokeFixed()
}
const resetStickyOffsetForStorePage = () => {
  const { headerFixedInstance } = commonHeaderRef.value?.commonHeaderContext
  if (headerFixedInstance.warpDom?.offsetHeight !== 0) return
  headerFixedInstance.init?.()
  headerFixedInstance.invokeFixed?.(true)
}
const setHeaderForceFixed = (forceFixed) => {
  commonHeaderRef.value?.commonHeaderContext.headerFixedInstance?.invokeFixed(forceFixed)
}

// 监听 $route start
router.afterEach((to, from) => {
  routeRef.value = to
  setHeaderByRouteAfterEach(to, from)
})
// 监听 $route end

watch(() => props.showAppHeader, (val) => {
  if (val) {
    nextTick(() => {
      const { headerFixedInstance } = commonHeaderRef.value?.commonHeaderContext
      if (headerFixedInstance.lastHeight !== 0) return
      headerFixedInstance.init?.()
    })
  }
})
watch(() => hasNewTopBanner.value, () => {
  resetStickyOffset()
})
// BCommonHeader组件 end

onMounted(() => {
  // appEventCenter start

  appEventCenter.on('visibleHeaderChange', onVisibleHeaderChange)
  appEventCenter.on('resetHeaderAppStickyOffset', resetStickyOffset)
  appEventCenter.on('resetStickyOffsetForStorePage', resetStickyOffsetForStorePage)
  appEventCenter.on('pageOnload', () => {
    useProxyHosts().CommonHeader?.trigger('pageOnload')
  })
  window.vBus && window.vBus.on('onBranchShow', resetStickyOffset)
  // appEventCenter end

  // 将当前方法挂载在window上，方便强制调用更新
  window.forceUpdateHeaderFixed = ({ forceFixed, routeName } = {}) => {
    if (routeName) {
      routeRef.value = { ...routeRef.value, name: routeName }
    }
    setHeaderForceFixed(forceFixed)
    // showBlock 设置为 false
    emit('changeHomeImmersiveInfo', { commonHeaderBg: {
      // ...props.commonHeaderBg,
      showBlock: false,
    } })
  }
  nextTick(() => {
    // 处理header占位dom高度问题
    const { headerFixedInstance } = commonHeaderRef.value?.commonHeaderContext
    if (headerFixedInstance.lastHeight === 0){
      headerFixedInstance.init?.()
    }
  })
})


const isGoodsDetailNavigationsearchAbtHit = computed(() => {
  return goodsDetailInfo.value.productNavigationSearchInfo?.abtHit
})
const showProductDetailTab = computed(() => {
  return isGoodsDetailPage.value && isGoodsDetailNavigationsearchAbtHit.value
})

onUnmounted(() => {
  appEventCenter.off('visibleHeaderChange')
  appEventCenter.off('resetHeaderAppStickyOffset')
  appEventCenter.off('resetStickyOffsetForStorePage')
  window.vBus && window.vBus.off('onBranchShow')
})
</script>

<style lang="less">
@zIndex: 11;
.app-header {
  .app-header {
    &__icon-left {
      z-index: @zindex-header;
      margin-right: 0.32rem;
    }
    &__icon-right {
      margin-left: 0.32rem;
    }
  }
  .app-header__wrap-hidden {
    visibility: hidden
  }
  .bsc-common-header__wrap {
    overflow: hidden;
  }
  .new-header-share-icon {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #222;
    line-height: 1;
  }
  &__show-input {
    .bsc-common-header__center {
      left: 2.24rem;
      transform: translateY(-50%);
      z-index: @zindex-header;
    }
  }
  .slide-out-to-left {
    animation: slide-out-to-left 0.35s forwards;
  }
  .slide-in-from-left {
    animation: slide-in-from-left 0.35s ease-in-out forwards;
  }
  .slide-out-to-right {
    animation: slide-out-to-right 0.35s forwards;
  }
  .slide-in-from-right {
    animation: slide-in-from-right 0.35s ease-in-out forwards;
  }
  // 从左边滑入
  @keyframes slide-in-from-left {
    0% {
      transform: translateX(-120%);
    }
    100% {
      transform: translateX(0);
    }
  }
  // 从右边滑入
  @keyframes slide-in-from-right {
    0% {
      transform: translateX(calc(120% + 1.6rem));
    }
    100% {
      transform: translateX(0);
    }
  }
  // 从左边滑出
  @keyframes slide-out-to-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-120%);
    }
  }
  // 从右边滑出
  @keyframes slide-out-to-right {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(120%);
    }
  }

}
.side-shein-info {
  margin: 0 auto;
  padding: 0.26rem 0;
  border-bottom: 0.026rem solid #e5e5e5;
  width: 7.14rem;
}
</style>

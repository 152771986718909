import { preloadImg } from 'public/src/pages/ccc-campaigns/components/common/utils.js'
async function fetchImgs (ssrHTML) {
  let imgPendings
  try {
    const fragment = document.createRange().createContextualFragment(ssrHTML)
    const imgs = [
      ...fragment.querySelectorAll('.checkout__container .checkout-component__image.fsp-element .checkout-component__image_img'),
      ...fragment.querySelectorAll('.checkout__container .payment-logo-img'),
      ...fragment.querySelectorAll('.checkout__container .card-logo-img'),
    ]
    if (imgs.length) {
      imgPendings = preloadImg(imgs.map(_ => _.getAttribute('src')).filter(_ => _), null, 1000)
    }
  } catch (error) {
    console.error(error)
  }
  return imgPendings
}
async function fetchCheckoutContentInfoApi() {
  const res = await window.checkoutPending
  const { contextForSSR, ssrHTML, ssrVueCSS, refreshTask } = res || {}
  window.gbCheckoutSsrData = contextForSSR
  if (ssrHTML) {
    return {
      html: ssrHTML,
      style: ssrVueCSS || '',
      contextForSSR,
      refreshTask,
      imgPendings: fetchImgs(ssrHTML)
    }
  }
  return {
    contextForSSR
  }
}

const spaCheckout = {
  // 页面路由name
  routeName: 'checkout',
  /**
   * @param {Object} param
   * @returns {Promise<{html: string, style: string, ...}>}
  */
  notFilterHtml: true,  // 不过滤html，使用传递的html上屏。默认为false，会通过指定标记来进行过滤html
  request: async function () {
    try {
      return await fetchCheckoutContentInfoApi()
    } catch (reload) {
      typeof reload === 'function' ? reload() : location.reload()
    }
  },
  // 调度器：上屏html后，回调控制组件的渲染
  scheduler: async (result, fn, screenUpdate) => {
    let { imgPendings, refreshTask } = result?.data || {}
    if (refreshTask) {
      try {
        const res = await refreshTask
        const { contextForSSR, ssrHTML, ssrVueCSS } = res || {}
        window.gbCheckoutSsrData = contextForSSR
        imgPendings = ssrHTML ? fetchImgs(ssrHTML) : null
        screenUpdate({ html: ssrHTML, style: ssrVueCSS })
      } catch (reload) {
        typeof reload === 'function' ? reload() : location.reload()
      }
    }
    requestAnimationFrame(() => { // 上屏前
      imgPendings
        ? imgPendings.then(() => requestAnimationFrame(() => fn()))
        : requestAnimationFrame(() => fn())
    })
  },
}

export default spaCheckout

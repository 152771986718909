const { langPath } = gbCommonInfo

const faqRoutes = [
  {
    path: `${langPath}/faq`,
    name: 'faq',
    meta: {
      pageType: 'faq',
      scrollTop: true,
      notNeedCommonHeader: true,
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "faq" */ '../../faq/container_index'),
    alias: `${langPath}/faq/index`,
  },
  // {
  //   path: `${langPath}/faq/index`,
  //   name: 'faq',
  //   meta: {
  //     pageType: 'faq',
  //     scrollTop: true,
  //     notNeedCommonHeader: true,
  //     isCssInVue: true
  //   },
  //   component: () => import(/* webpackChunkName: "faq" */ '../../faq/container_index')
  // },
  {
    path: `${langPath}/faq/list`,
    name: 'faq-list',
    meta: {
      pageType: 'faq',
      scrollTop: true,
      notNeedCommonHeader: true,
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "faq" */ '../../faq/container_list')
  },
  {
    path: `${langPath}/faq/detail`,
    name: 'faq-detail',
    meta: {
      pageType: 'faq',
      scrollTop: true,
      notNeedCommonHeader: true,
      isCssInVue: true
    },
    component: () => import(/* webpackChunkName: "faq" */ '../../faq/container_detail')
  },
]

export {
  faqRoutes
}

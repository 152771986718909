
import storePageDataManager from 'public/src/pages/store_pages/js/storePageDataManager.js'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import {  SILogger } from 'public/src/pages/common/monitor/index'
import timeCollectionManager from 'public/src/pages/store_pages/js/timeCollectionManager.js'

export const getStoreComponent = () => {
  return {
    component: prefetchResource.importAsyncComponent({
      chunkName: 'store_container', // 跟webpackChunkName保持一致
      componentFactory: () => import( /* webpackChunkName: 'store_container' */ '../../store_pages/StoreContainer.vue'),
    })
  }
}

export const createStoreRoute = langPath => ({
  path: `${langPath}/:catchAll(.+)-store-:storeCode(\\d{10}).html`,
  alias: `${langPath}/store/home`,
  name: 'page_store',
  ...getStoreComponent(),
  beforeEnter: (to, from, next) => {
    storePageDataManager.fromFullPath = from?.fullPath || ''
    timeCollectionManager.recordPointTime('beforeEnterTime', Date.now())
    // to.meta.beforeEnterTime = Date.now()
    // spa 客户端请求提前
    if(typeof window !== 'undefined' && typeof gbCommonInfo !== 'undefined'){
      if(gbCommonInfo?.contextForSSR?.ssrPageType !== 'page_store' || storePageDataManager.pageSsrDataResolved){
        storePageDataManager.prerenderRequest(to)
      }
    }
    next()
  },
  meta: {
    type: 'store',
    keepAlive: true,
    isCssInVue: true,
    pageType: 'page_store',
    monitorTag: 'page_store',
    noNeedEmitPageUnload: true,
    ignoreScrollBehavior: {
      status: true,
    },
    fspPreReporterHandler: (data, options)=>{
      try {
        // 耗时比较长的页面日志上报
        const { value, payload  } = options || {}
        data.page_from = timeCollectionManager._topTabActive || ''
        // apm 上报
        const { timeCollections, recordPointTimes } = timeCollectionManager
       
        const tpmDatas = Object.entries({
          ...timeCollections,
          'storePageFsp': value,
        }).map(([name, val])=>({
          key_path: name,
          values: {
            num: val
          }
        }))
        const { beforeEnterTime, onActivatedTime, resolvePageDataEndTime } = recordPointTimes || {}
        if(beforeEnterTime && onActivatedTime){
          tpmDatas.push({
            key_path: 'beforeEnterToonActivatedTime',
            values: {
              num: onActivatedTime - beforeEnterTime
            }
          })
        }
       
        if(beforeEnterTime && resolvePageDataEndTime){
          tpmDatas.push({
            key_path: 'storeResolveDataEndToFsp',
            values: {
              num: value - (resolvePageDataEndTime - beforeEnterTime),
            }
          })
        }

        data.page_version = timeCollectionManager._requestMarking || 'Other'
        const tpmParamsData = {
          page_name: 'page_store',
          page_from: data.page_from,
          render_type: data.render_type,
          resource: 'page_store_full_track', // 全链路数据
          page_version: data.page_version,
          data: tpmDatas,
        }
        // console.log('===========tpmParamsData', tpmParamsData)
        window.TPM?.run({
          marketing: 'ClientAnalytics',
          method: '_defineTrack',
          params: { data: tpmParamsData, options: { random: 1, immediate: true, } }
        })

        // sky-log
        if(value > 2500){
          const { serverTotalTime, storePageGetRequestTime } = timeCollections || {}
          let message = `fsp: ${value}`
          if(serverTotalTime){
            message += ` serverTotalTime: ${serverTotalTime}`
          }
          if(storePageGetRequestTime){
            message += ` storePageGetRequestTime: ${storePageGetRequestTime}`
          }
          if(beforeEnterTime, onActivatedTime){
            message += ` beforeEnter->onActivated: ${onActivatedTime - beforeEnterTime}`
          }
          const params = {
            url: window.location.href,
            fromFullPath: storePageDataManager.fromFullPath,
            tab: data.page_from,
            render_type: data.render_type,
            elementClassName: payload?.elementClassName || '',
            value
          }
          SILogger.logInfo({
            tag: 'store_page_fsp_long_time',
            message,
            params,
          }, 
          {
            random: 0.1, // 采样率
          })
        }
        return data
      } catch (error) {
        console.log('========error', error)
        return data
      }
    }
  },
})


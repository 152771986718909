import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { triggerHydrationSsrRequest } from 'public/src/spaHydration/index.js'

const { langPath } = gbCommonInfo
if (/\/cart\/?$/.test(location.pathname)) {
  window.fetchCartContentInfo = null
}

const getCartComponent = () => {
  return prefetchResource.importAsyncComponent({
    chunkName: 'cartv2_index', // 跟webpackChunkName保持一致
    componentFactory: () => import( /* webpackChunkName: 'cartv2_index' */ '../../cart_v2/container.vue')
  })
}

const getCartShareLandingComponent = () => {
  return () => import(/* webpackChunkName: "cart_share_bff" */ '../../cart/share-landing/App.vue')
}

const cartRoutes = [
  {
    path: `${langPath}/cart`,
    name: 'cart',
    component: getCartComponent(),
    beforeEnter: (to, from, next) => {
      if (from.name && from.name !== 'cart') {
        if (window.fetchCartContentInfo === null) return next()
        triggerHydrationSsrRequest('cart')
        window.fetchCartContentInfo = null
        next()
      } else {
        next()
      }
    },
    meta: {
      keepAlive: true,
      pageType: 'cart',
      // toSavePosition: ['page_goods_detail'],
      notNeedCommonHeader: true,
      monitorTag: 'cart',
      isCssInVue: true,
    }
  },
  {
    path: `${langPath}/cart/share/select`,
    name: 'cartShareSelect',
    component: () => import(/* webpackChunkName: "cart_share_select" */ '../../cart_share_select/container.vue'),
    meta: {
      notNeedCommonHeader: true,
      keepAlive: true,
    },
  },
  {
    path: `${langPath}/cart/share/landing`,
    name: 'cartShareLanding',
    component: getCartShareLandingComponent(),
    meta: {
      notNeedCommonHeader: true,
    },
  },
  {
    path: `${langPath}/cart/add_on_item`,
    name: 'cartAddOnItem',
    component: () => import(/* webpackChunkName: "cart_add_on_item" */'../../cart_add_on_item/container.vue'),
    meta: {
      keepAlive: false,
      pageType: 'cart',
      notNeedCommonHeader: true,
      monitorTag: 'cart-add-on-item',
      isCssInVue: true,
    },
  }
]

export {
  cartRoutes
}

import { ref, computed, onMounted } from 'vue'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { web_cart_entry_click } from '@/public/src/pages/cart_v2/utils/metricTagsConfig.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { ICON_SCROLL_DISTANCE } from 'public/src/pages/components/app_header/components/LegacyHeader/utils/const.js'
import useWish from './useWish.js'


const storePageNames = [
  'page_store_items',
  'page_store_brands',
  'page_store_category',
  'page_store_home',
  'page_store',
  'page_store_pre_search'
]

const listPageNames = [
  'PTFeedbackRecList',
  'productList',
  'page_store',
  'PTRecProductList',
]

const getStoreCodeFromUrl = (route) => {
  let storeCode = route.query?.store_code
  if (!storeCode) {
    const reg = /.+-store-(\d{10})\.html$/ // store reg
    const matchResult = route.path.match(reg) || []
    storeCode = matchResult[1] || ''
  }
  return storeCode
}

export default function useCart({ route, searchStickyInfo, isShowInput }) {
  const { showWish } = useWish({ route })

  const abtInfo = ref({})

  const storePageHide = computed(() => {
    if (
      route.value.path.includes('/pdsearch') &&
      route.value.query?.search_type === 'store'
    )
      return true
    return !!(
      storePageNames.includes(route.value.name) ||
      route.value.query?.search_type === 'store' ||
      route.value.query?.pageType === 'store'
    ) && !!getStoreCodeFromUrl(route.value)
  })

  const productDetailHide = computed(() => {
    const isProductDetail = route.value?.meta?.pageType === 'productDetail'
    const abtHide = abtInfo.value.hovercart?.p?.oldcart === 'none'
    return isProductDetail && abtHide
  })

  const listHide = computed(() => {
    const isList = listPageNames.includes(route.value.meta?.pageType)
    const abtHide = abtInfo.value.listiconBag?.p?.listiconBag === 'A'
    return isList && abtHide
  })

  // 列表搜索框平铺 （隐藏购物车按钮）
  const showListSearchLayFlat = ref(false)
  const handleListSearchLayFlat = (listSearchLayFlat) => {
    showListSearchLayFlat.value = listSearchLayFlat
  }

  const campaignsHide = ref(false)

  const handleCampaignsHide = () => {
    let isHide
    if (typeof window === 'undefined' || route.value?.meta?.pageType !== 'campaigns') {
      isHide = false
    } else {
      isHide = window.gbCommonInfo.contextForSSR.content?.content?.some(
        item => item.styleType === 'CART_ICON'
      )
    }
    campaignsHide.value = isHide
  }

  onMounted(async () => {
    const { 
      listiconBag, 
      hovercart, 
    } = await getUserAbtData()
    abtInfo.value = {
      listiconBag,
      hovercart,
    }

    handleCampaignsHide()
  })

  const showCart = computed(() => {
    if (storePageHide.value) return false
    if (productDetailHide.value) return false
    if (campaignsHide.value) return false
    if (listHide.value) return false
    if(showWish.value) return false
    if(showListSearchLayFlat.value) return false
    if(searchStickyInfo.value.isSearchSticky && searchStickyInfo.value.searchSrollHeight > ICON_SCROLL_DISTANCE && isShowInput.value) return false
    return true
  })

  const cartIconStyle = computed(() => {
    return {
      opacity: isShowInput.value ? (searchStickyInfo.value.isSearchSticky ? (1 - searchStickyInfo.value.searchSrollHeight / ICON_SCROLL_DISTANCE) : 1) : 1,
      willChange: 'opacity',
      transition: 'opacity 0.2s ease-in-out',
    }
  })

  const hasFloatCartInDetailPage = computed(() => {
    const isProductDetail = route.value?.meta?.pageType === 'productDetail'
    const hasFloatCart = ['show', 'showdefault'].includes(abtInfo.value.hovercart?.p?.hovercart)
    return isProductDetail && hasFloatCart
  })

  const onBeforeClickCart = () => {
    web_cart_entry_click('2')
    markPoint('toNextPageClick', 'public')
  }

  return {
    showCart,
    onBeforeClickCart,
    handleListSearchLayFlat,
    showListSearchLayFlat,
    cartIconStyle,
    hasFloatCartInDetailPage
  }
}
